enum Actions {
  // action types
  CLEAR_ERRORS = 'clearErrors',
  RESET_ADMINS_STORE = 'resetAdminsStore',
  RESET_APPRAISERS_STORE = 'resetAppraisersStore',
  RESET_ROLES_STORE = 'resetRolesStore',
  RESET_USERS_STORE = 'resetUsersStore',
  RESET_ADS_PACKAGE_STORE = 'resetAdsPackageStore',
  RESET_ADS_SERVICE_STORE = 'resetAdsServiceStore',
  RESET_ADS_STORE = 'resetAdsStore',
  RESET_PROPERTY_TYPES_STORE = 'restPropertyTypesStore',
  RESET_AD_TYPES_STORE = 'resetAdTypeStore',
  RESET_APPRAISALS_STORE = 'resetAppraisalsStore',
  RESET_BANKS_STORE = 'resetBanksStore',
  RESET_ANSWERS_STORE = 'resetAnswersStore',
  RESET_BANNERS_STORE = 'resetBannersStore',
  RESET_AREAS_STORE = 'resetAreasStore',
  RESET_PROPERTIES_STORE = 'resetPropertiesStore',
  RESET_LOCATIONS_STORE = 'resetLocationsStore',
  RESET_ORGANIZERS_STORE = 'resetOrganizersStore',
  RESET_AUCTION_PROPERTY_TYPES_STORE = 'resetAuctionPropertyTypesStore',
  RESET_AUCTIONS_STORE = 'resetAuctionStore',
  RESET_CCE_CATEGORIES_STORE = 'resetCceCategoriesStore',
  RESET_CCE_GROUPS_STORE = 'resetCceGroupsStore',
  RESET_CCE_SUB_CATEGORIES_STORE = 'resetCceSubCategoriesStore',
  RESET_LOANS_FUNDS_REQUEST_STORE = 'resetLoansFundsRequestStore',
  RESET_IVE_AREAS_STORE = 'restIveAreasStore',
  RESET_IVE_FACTOR_OPTIONS_STORE = 'resetFactorOptionsStore',
  RESET_BANK_RATES_STORE = 'resetBankRatesStore',
  RESET_NEWS_STORE = 'resetNewsStore',
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  UPDATE_USER = 'updateUser',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  GET_ADMIN_USERS = 'getAdminUsers',
  GET_AUCTIONS = 'getAuctions',
  GET_APPRAISERS = 'getAppraisers',
  GET_ALL_PROPERTY_TYPES = 'getAllPropertyTypes',
  GET_USERS = 'getUsers',
  GET_APPRAISALS = 'getAppraisals',
  GET_PERMISSIONS = 'getPermissions',
  GET_ORGANIZER_LOCATIONS = 'getOrganizerLocations',
  GET_ADMIN_USER = 'getAdminUser',
  GET_AUCTION = 'getAuction',
  GET_APPRAISER = 'getAppraiser',
  GET_USER = 'getUser',
  GET_ALL_AREAS_LIST = 'getAllAreasList',
  GET_APPRAISAL = 'getAppraisal',
  BULK_APPROVE_ADS = 'bulkApproveAds',
  BULK_REJECT_ADS = 'bulkRejectAds',
  APPROVE_ALL_ADS = 'approveAllAds',
  UPDATE_ADMIN_CURRENT_PAGE = 'updateAdminCurrentPage',
  UPDATE_APPRAISER_CURRENT_PAGE = 'updateAppraiserCurrentPage',
  UPDATE_USER_CURRENT_PAGE = 'updateUserCurrentPage',
  UPDATE_APPRAISAL_CURRENT_PAGE = 'updateAppraisalCurrentPage',
  UPDATE_AUCTION_CURRENT_PAGE = 'updateAuctionCurrentPage',
  UPDATE_ROLE_CURRENT_PAGE = 'updateRoleCurrentPage',
  UPDATE_BANK_CURRENT_PAGE = 'updateBankCurrentPage',
  UPDATE_ANSWER_CURRENT_PAGE = 'updateAnswerCurrentPage',
  UPDATE_IVE_FACTOR_OPTION_CURRENT_PAGE = 'updateIveFactorOptionCurrentPage',
  UPDATE_AUCTION_PROPERTY_TYPE_CURRENT_PAGE = 'updateAuctionPropertyTypeCurrentPage',
  UPDATE_LOCATION_CURRENT_PAGE = 'updateLocationCurrentPage',
  UPDATE_ORGANIZER_CURRENT_PAGE = 'updateOrganizerCurrentPage',
  UPDATE_AD_TYPE_CURRENT_PAGE = 'updateTypesCurrentPage',
  UPDATE_PROPERTY_CURRENT_PAGE = 'updatePropertyCurrentPage',
  UPDATE_FAQ_CURRENT_PAGE = 'updateFaqCurrentPage',
  UPDATE_AREA_CURRENT_PAGE = 'updateAreaCurrentPage',
  UPDATE_QUALITY_RANGE_CURRENT_PAGE = 'updateQualityRangeCurrentPage',
  UPDATE_QUALITY_GROUP_CURRENT_PAGE = 'updateQualityGroupCurrentPage',
  UPDATE_IVE_AREA_CURRENT_PAGE = 'updateIveAreaCurrentPage',
  UPDATE_NEWS_CURRENT_PAGE = 'updateNewsCurrentPage',
  UPDATE_PROPERTY_TYPE_CURRENT_PAGE = 'updatePropertyTypeCurrentPage',
  CREATE_ADMIN_ACCOUNT = 'createAdminAccount',
  CREATE_IVE_VALUE = 'createIveValue',
  CREATE_APPRAISER_ACCOUNT = 'createAppraiserAccount',
  CREATE_AUCTION = 'createAuction',
  CREATE_AUCTION_PROPERTY = 'createAuctionProperty',
  UPDATE_AUCTION_PROPERTY = 'updateAuctionProperty',
  UPLOAD_AUCTION_VIDEO = 'uploadAuctionVideo',
  UPLOAD_ORGANIZER_LOGO = 'uploadOrganizerLogo',
  UPLOAD_AUCTION_ATTACHMENTS = 'uploadAuctionAttachments',
  CREATE_ROLE = 'createRole',
  DELETE_ROLE = 'deleteRole',
  DELETE_IVE_VALUE = 'deleteIveValue',
  UPDATE_IVE_VALUE = 'updateIveValue',
  UPDATE_ROLE = 'updateRole',
  CREATE_BANK = 'createBank',
  CREATE_ANSWER = 'createAnswer',
  CREATE_IVE_FACTOR_OPTION = 'createIveFactorOption',
  CREATE_AUCTION_PROPERTY_TYPE = 'createAuctionPropertyType',
  CREATE_LOCATION = 'createLocation',
  CREATE_ORGANIZER = 'createOrganizer',
  DELETE_LOCATION = 'deleteLocation',
  DELETE_BANK = 'deleteBank',
  DELETE_ANSWER = 'deleteAnswer',
  DELETE_IVE_FACTOR_OPTION = 'deleteIveFactorOption',
  DELETE_AUCTION_PROPERTY_TYPE = 'deleteAuctionPropertyType',
  DELETE_ORGANIZER = 'deleteOrganizer',
  DELETE_AUCTION_PROPERTY = 'deleteAuctionProperty',
  UPDATE_BANK = 'updateBank',
  UPDATE_ANSWER = 'updateAnswer',
  UPDATE_IVE_FACTOR_OPTION = 'updateIveFactorOption',
  UPDATE_IVE_FACTOR_OPTION_DEFAULT = 'updateIveFactorOptionDefault',
  UPDATE_AUCTION_PROPERTY_TYPE = 'updateAuctionPropertyType',
  UPDATE_LOCATION = 'updateLocation',
  CREATE_AD_TYPE = 'createAdType',
  DELETE_AD_TYPE = 'deleteAdType',
  UPDATE_AD_TYPE = 'updateAdType',
  UPDATE_ORGANIZER = 'updateOrganizer',
  CREATE_PROPERTY = 'createProperty',
  DELETE_PROPERTY = 'deleteProperty',
  UPDATE_PROPERTY = 'updateProperty',
  CREATE_FAQ = 'createFaq',
  DELETE_FAQ = 'deleteFaq',
  UPDATE_FAQ = 'updateFaq',
  UPDATE_CONTACT = 'updateContact',
  GET_CONTACTS = 'getContacts',
  UPDATE_CONTACT_CURRENT_PAGE = 'updateContactCurrentPage',
  CREATE_AREA = 'createArea',
  CREATE_IVE_AREA = 'createIveArea',
  CREATE_IVE_CLONE_AREA = 'createIveCloneArea',
  DELETE_AREA = 'deleteArea',
  DELETE_IVE_AREA = 'deleteIveArea',
  UPDATE_AREA = 'updateArea',
  UPDATE_IVE_AREA = 'updateIveArea',
  CREATE_QUALITY_RANGE = 'createQualityRange',
  DELETE_QUALITY_RANGE = 'deleteQualityRange',
  UPDATE_QUALITY_RANGE = 'UpdateQualityRange',
  CREATE_QUALITY_GROUP = 'createQualityGroup',
  DELETE_QUALITY_GROUP = 'deleteQualityGroup',
  UPDATE_QUALITY_GROUP = 'UpdateQualityGroup',
  UPDATE_GROUPS_WEIGHT = 'UpdateGroupsWieght',
  CREATE_PROPERTY_TYPE = 'createPropertyType',
  UPDATE_PROPERTY_TYPE = 'updatePropertyType',
  CREATE_QUESTION = 'createQuestion',
  DELETE_QUESTION = 'deleteQuestion',
  UPDATE_QUESTION = 'UpdateQuestion',
  UPDATE_QUESTION_WEIGHT = 'UpdateQuestionsWeight',
  CREATE_BANK_RATE = 'createBankRate',
  DELETE_BANK_RATE = 'deleteBankRate',
  UPDATE_BANK_RATE = 'updateBankRate',
  CREATE_NEWS = 'createNews',
  PULL_URL_NEWS = 'PullUrlNews',
  DELETE_NEWS = 'deleteNews',
  UPDATE_NEWS = 'updateNews',
  GET_ROLE = 'getRole',
  GET_LOCATION = 'getLocation',
  GET_BANK = 'getBank',
  GET_ANSWER = 'getAnswer',
  GET_IVE_FACTOR_OPTION = 'getIveFactorOption',
  GET_AUCTION_PROPERTY_TYPE = 'getAuctionPropertyType',
  GET_AD_TYPE = 'getAdType',
  GET_ORGANIZER = 'getOrganizer',
  GET_PROPERTY = 'getProperty',
  GET_FAQ = 'getFaq',
  GET_AREA = 'getArea',
  GET_QUALITY_RANGE = 'getQualityRange',
  GET_QUALITY_GROUP = 'getQualityGroup',
  GET_IVE_AREA = 'getIveArea',
  GET_CONTACT = 'getContact',
  GET_PROPERTY_TYPE = 'getPropertyType',
  GET_ABOUT_US = 'getUboutUs',
  GET_TERMS_AND_CONDITIONS = 'getTermsAndConditions',
  GET_BANK_RATE = 'getBankRate',
  GET_ONE_NEWS = 'getOneNews',
  UPDATE_ADMIN_ACCOUNT = 'updateAdminAccount',
  UPDATE_APPRAISER_ACCOUNT = 'updateAppraiserAccount',
  DELETE_ADMIN_ACCOUNT = 'deleteAdminAccount',
  DELETE_APPRAISER_ACCOUNT = 'deleteAppraiserAccount',
  UPDATE_APPRAISAL = 'updateAppraisal',
  UPDATE_AUCTION = 'updateAuction',
  DELETE_APPRAISAL = 'deleteAppraisal',
  DELETE_AUCTION = 'deleteAuction',
  CHANGE_LOCALE = 'changeLocale',
  GET_ROLES = 'getRoles',
  GET_BANKS = 'getBanks',
  GET_ANSWERS = 'getAnswers',
  GET_IVE_FACTOR_OPTIONS = 'getIveFactorOptions',
  GET_IVE_AREA_ESTIMATIONS = 'getIveAreaEstimations',
  GET_AUCTION_PROPERTY_TYPES = 'getAuctionPropertyTypes',
  GET_FAQS = 'getFaqs',
  GET_LOCATIONS = 'getLocations',
  GET_AD_TYPES = 'getAdTypes',
  GET_PROPERTIES = 'getProperties',
  GET_AREAS = 'getAreas',
  GET_QUALITY_RANGES = 'getQualityRanges',
  GET_QUALITY_GROUPS = 'getQualityGroups',
  GET_IVE_AREAS = 'getIveAreas',
  GET_ALL_IVE_AREAS = 'getAllIveAreas',
  GET_PROPERTY_TYPES = 'getPropertyTypes',
  GET_ORGANIZERS = 'getOrganizers',
  GET_ROLES_GROUPS = 'getRolesGroups',
  GET_BANK_RATES = 'getBankRates',
  GET_BANK_RATES_ENUM_ALL = 'getBankRatesEnumAll',
  GET_NEWS = 'getNews',
  UPDATE_APPRAISER_BANKS = 'updateAppraiserBanks',
  UPDATE_APPRAISER_PRICING = 'updateAppraiserPrices',
  GET_CCE_GROUPS = 'getCceGroups',
  GET_CCE_GROUP = 'getCceGroup',
  CREATE_CCE_GROUP = 'createCceGroup',
  DELETE_CCE_GROUP = 'deleteCceGroup',
  UPDATE_CCE_GROUP = 'updateCceGroup',
  UPDATE_CCE_GROUP_CURRENT_PAGE = 'updateCceGroupCurrentPage',
  GET_CCE_CATEGORIES = 'getCceCategories',
  GET_CCE_CATEGORIE = 'getCceCategorie',
  CREATE_CCE_CATEGORIE = 'createCceCategorie',
  DELETE_CCE_CATEGORIE = 'deleteCceCategorie',
  UPDATE_CCE_CATEGORIE = 'updateCceCategorie',
  UPDATE_CCE_CATEGORIE_CURRENT_PAGE = 'updateCceCategorieCurrentPage',
  UPDATE_BANK_RATE_CURRENT_PAGE = 'updateBankRateCurrentPage',
  GET_ALL_CCE_GROUPS = 'getAllSubCceGroups',
  GET_CCE_SUB_CATEGORIES = 'getCceSubCategories',
  GET_CCE_SUB_CATEGORIE = 'getCceSubCategorie',
  CREATE_CCE_SUB_CATEGORIE = 'createCceSubCategorie',
  DELETE_CCE_SUB_CATEGORIE = 'deleteCceSubCategorie',
  UPDATE_CCE_SUB_CATEGORIE = 'updateCceSubCategorie',
  UPDATE_CCE_SUB_CATEGORIE_CURRENT_PAGE = 'updateCceSubCategorieCurrentPage',
  GET_ALL_CCE_CATEGORIES = 'getAllCceSubCategories',
  GET_ALL_CCE_QUALITY = 'getAllCceQuality',
  UPDATE_CCE_SUB_CATEGORIE_OPTIONS = 'updateCceSubCategorieOptions',
  GET_AUCTION_PROPERTIES = 'getAuctionProperties',
  GET_AUCTION_PROPERTY = 'getAuctionProperty',
  GET_ALL_AUCTION_STATUSES = 'getAllAuctionStatuses',
  GET_ALL_AUCTION_PROPERTY_TYPES = 'getAllAuctionPropertyTypes',
  UPDATE_REGISTERED_TRADE_CURRENT_PAGE = 'updateRegisteredTradePage',
  GET_REGISTERED_TRADE = 'getRegisteredTrade',
  GET_REGISTERED_TRADES = 'getRegisteredTrades',
  CREATE_REGISTERED_TRADE = 'createRegisteredTrade',
  UPDATE_REGISTERED_TRADE = 'updateRegisteredTrade',
  DELETE_REGISTERED_TRADE = 'deleteRegisteredTrade',
  GET_AREAS_DDL = 'getAreasDdl',
  UPDATE_REGISTERED_TRADE_SCRAPING_CURRENT_PAGE = 'updateRegisteredTradePage',
  GET_REGISTERED_TRADE_SCRAPING = 'getRegisteredTradeScraping',
  GET_REGISTERED_TRADES_SCRAPING = 'getRegisteredTradesScraping',
  CREATE_REGISTERED_TRADE_SCRAPING = 'createRegisteredTradeScraping',
  UPDATE_REGISTERED_TRADE_SCRAPING = 'updateRegisteredTradeScraping',
  DELETE_REGISTERED_TRADE_SCRAPING = 'deleteRegisteredTradeScraping',
  PULL_REGISTERED_TRADE_SCRAPING = 'pullRegisteredTradeScraping',
  UPDATE_ABOUT_US = 'updateAboutUs',
  UPDATE_TERMS_AND_CONDITIONS = 'updateTermsAndConditions',
  UPDATE_AD_CURRENT_PAGE = 'updateAdCurrentPage',
  CREATE_AD = 'createAd',
  DELETE_AD = 'deleteAd',
  UPDATE_AD = 'updateAd',
  GET_AD = 'getAd',
  GET_ADS = 'getAds',
  UPDATE_AD_FILTER = 'updateAdFilter',
  GET_ADS_PACKAGES_LIST = 'getAdsPackages',
  UPDATE_AD_STATUS = 'updateAdStatus',
  UPDATE_REGISTERED_TRADE_FILTER = 'updateRegisteredTradeFilter',
  GET_ALL_PROPERTY_TYPE = 'getAllPropertyType',
  UPDATE_LOANS_FUNDS_REQUEST_CURRENT_PAGE = 'updateLoandFundsRequestCurrentPage',
  GET_LOANS_FUNDS_REQUESTS = 'getLoansFundsRequest',
  GET_IVE_FACTOR_ESTIMATION_OPTIONS = 'getIveFactorEstimationOptions',
  GET_LOAN_FUNDS_REQUEST = 'getLoanFundsRequest',
  UPDATE_ADS_SERVICES_CURRENT_PAGE = 'updateAdsServicesCurrentPage',
  GET_AD_SERVICE = 'getAdService',
  GET_ADS_SERVICES = 'getAdsService',
  DELETE_AD_SERVICE = 'deleteAdService',
  UPDATE_AD_SERVICE = 'updateAdService',
  CREATE_AD_SERVICE = 'createAdService',
  GET_ADS_PACKAGES = 'getAdPackage',
  DELETE_AD_PACKAGE = 'deleteAdPackage',
  UPDATE_ADS_PACKAGES_CURRENT_PAGE = 'updateAdsPackageCurrentPage',
  GET_SERVICE_LIST = 'getServiceList',
  CREATE_AD_PACKAGE = 'createAdPackage',
  GET_ADS_PACKAGE = 'getAdsPackage',
  UPDATE_ADS_PACKAGE = 'updateAdSPAckage',
  CREATE_BANNER = 'createBanner',
  DELETE_BANNER = 'deleteBanner',
  UPDATE_BANNER = 'updateBnnaer',
  UPDATE_BANNER_CURRENT_PAGE = 'updateBannerCurrentPage',
  GET_BANNER = 'getBanner',
  GET_BANNERS = 'getBanners',
  GET_BANNER_ASSETS = 'getBannerAssets',
  GET_MERCHANT = 'getMerchants',
  UPLOAD_BANNER_IMAGE = 'uploadBannerLogo',
  UPDATE_LOANS_FUNDS_REQUEST = 'updateLoansFundsRequest',
  GET_ALL_AUCTION_ORGANISERS = 'getAllAuctionOrganiser',
  UPDATE_AUCTIONS_FILTER = 'updateAuctionsFilter',
  GET_IRE_AREAS = 'getIreAreas',
  GET_IRE_AREA = 'getIreArea',
  UPDATE_IRE_AREA_CURRENT_PAGE = 'updateIreAreaCurrentPage',
  UPDATE_IRE_AREA = 'updateIreArea',
  DELETE_IRE_AREA = 'deleteIreArea',
  CREATE_IRE_AREA = 'createIreArea',
  GET_ALL_IRE_AREAS = 'getAllIreAreas',
  GET_IRE_FACTOR_AREAS_VALUES = 'getIreFactorAreasValues',
  GET_IRE_FACTORS = 'getIreFactors',
  GET_IRE_FACTOR = 'getIreFactor',
  UPDATE_IRE_FACTOR_CURRENT_PAGE = 'updateIreFactorCurrentPage',
  UPDATE_IRE_FACTOR = 'updateIreFactor',
  DELETE_IRE_FACTOR = 'deleteIreFactor',
  DELETE_IRE_FACTOR_VALUES = 'deleteIreFactorValues',
  CREATE_IRE_FACTOR = 'createIreFactor',
  GET_All_IRE_TYPES = 'getAllIreTypes',
  GET_IRE_TYPES = 'getIreTypes',
  GET_IRE_TYPE = 'getIreType',
  UPDATE_IRE_TYPE_CURRENT_PAGE = 'updateIreTypeCurrentPage',
  UPDATE_IRE_TYPE = 'updateIreType',
  DELETE_IRE_TYPE = 'deleteIreType',
  CREATE_IRE_TYPE = 'createIreType',
  GET_IRE_SIZE_AREAS_VALUES = 'getIreSizeAreasValues',
  GET_IRE_SIZES = 'getIreSizes',
  GET_IRE_SIZE = 'getIreSize',
  UPDATE_IRE_SIZE_CURRENT_PAGE = 'updateIreSizeCurrentPage',
  UPDATE_IRE_SIZE = 'updateIreSize',
  DELETE_IRE_SIZE = 'deleteIreSize',
  CREATE_IRE_SIZE = 'createIreSize',
  UPDATE_IRE_SIZE_FACTOR_VALUES = 'updateIreSizeFactorValues',
  UPDATE_IRE_FACTOR_VALUES = 'updateIreFactorValues',
  GET_QUESTION = 'getQuestion',
  GET_QUESTIONS = 'getQuestions',
  EXPORT_IVE_AREA = 'exportIveArea',
  GET_ALL_PROPERTY_STATUS = 'getAllPropertyStatus',
  GET_HEADERS = 'getHeadersSection',
  GET_HEADER = 'getHeaderSection',
  UPDATE_HEADER_SECTION_CURRENT_PAGE = 'updateHeaderSectionCurrentPage',
  UPDATE_HEADER = 'updateHeader',
  UPDATE_DISCLAIMER_CURRENT_PAGE = 'updateDisclaimerCurrentPage',
  UPDATE_DISCLAIMER = 'updateDisclaimer',
  GET_DISCLAIMER = 'getDisclaimer',
  GET_DISCLAIMERS = 'getDisclaimers',
  GET_BANK_RATES_LOG_REPORTS = 'getBankRatesLogReports',
  IMPORT_BANK_RATE = 'importBankRate',
  EXPORT_RATES_LOGS = 'exportRatesLogs',
  UPDATE_BANK_RATES_FILTER = 'updateBankRatesFilter',
  RESET_AUCTIONS_PROPERTYS = 'resetAuctionsPropertys',
  GET_BANK_RATE_SETTINGS = 'getBankRateSettings',
  UPDATE_BANK_RATE_SETTINGS = 'updateBankRateSettings',
  UPDATE_IRE_V2_FACTOR = 'updateIreV2Factor',
  UPDATE_IRE_V2_Floor_FACTOR = 'updateIreV2FloorFactor',
  UPDATE_IRE_V2_Age_FACTOR = 'updateIreV2AgeFactor',
  UPDATE_IRE_V2_FACTOR_CURRENT_PAGE = 'updateIreV2FactorCurrentPage',
  GET_IRE_V2_FACTOR = 'getIreV2Factor',
  GET_IRE_V2_FACTORS = 'getIreV2Factors',
  GET_IRE_V2_Floor_FACTOR = 'getIreV2FloorFactor',
  GET_IRE_V2_Age_FACTOR = 'getIreV2AgeFactor',
  CREATE_IRE_V2_AREA = 'createIreV2Area',
  DELETE_IRE_V2_AREA = 'deleteIreV2Area',
  UPDATE_IRE_V2_AREA = 'updateIreV2Area',
  UPDATE_IRE_V2_AREA_CURRENT_PAGE = 'updateIreV2AreaCurrentPage',
  GET_IRE_V2_AREA = 'getIreV2Area',
  GET_IRE_V2_AREAS = 'getIreV2Areas',
  GET_ALL_IRE_V2_AREAS = 'getAllIreV2Areas',
  GET_All_IRE_V2_SIZE = 'getAllIreV2Size',
  GET_All_IRE_V2_PROPERTY_AGE = 'getAllIreV2PropertyAge',
  GET_All_IRE_V2_SIZE_DESC = 'getAllIreV2SizeDesc',
  UPDATE_IRE_SIZE_CONFIG_OPTIONS = 'updateIreSizeConfigOptions',
  UPDATE_IRE_SIZE_CONFIG_OPTIONS_V2 = 'updateIreSizeConfigOptionsV2',

  UPDATE_IRE_PROPERTY_AGE_CONFIG_OPTIONS = 'updateIrePropertyAgeConfigOptions',
  UPDATE_IRE_SIZE_DESC_VALUES = 'updateIreSizeDescValues',
  RESET_PROPERTY_CATEGORIES_STORE = 'resetPropertyCategoriesStore',
  CREATE_PROPERTY_CATEGORY = 'createPropertyCategory',
  UPDATE_PROPERTY_CATEGORY = 'updatePropertyCategory',
  UPDATE_PROPERTY_CATEGORY_CURRENT_PAGE = 'updatePropertyCategoryCurrentPage',
  GET_PROPERTY_CATEGORY = 'getPropertyCategory',
  GET_PROPERTY_CATEGORIES = 'getPropertyCategories',
  GET_ALL_PROPERTY_CATEGORIES = 'getAllPropertyCategories',
  DELETE_IRE_V2_SIZE_CONFIG = 'deleteIREV2SizeConfig',
  GET_ALL_AD_TYPES = 'getAllAdTypes',
  CREATE_IRE_INVESTMENT_AREA = 'createIreInvestmentArea',
  DELETE_IRE_INVESTMENT_AREA = 'deleteIreInvestmentArea',
  UPDATE_IRE_INVESTMENT_AREA = 'updateIreInvestmentArea',
  UPDATE_IRE_INVESTMENT_AREA_CURRENT_PAGE = 'updateIreInvestmentAreaCurrentPage',
  GET_IRE_INVESTMENT_AREA = 'getIreInvestmentArea',
  GET_IRE_INVESTMENT_AREAS = 'getIreInvestmentAreas',
  GET_ALL_IRE_INVESTMENT_AREAS = 'getAllIreInvestmentAreas',
  DELETE_IRE_INVESTMENT_SIZE_CONFIG = 'deleteIreInvestmentSizeConfig',
  GET_IRE_INVESTMENT_ALL_LOCATIONS = 'getIreInvestmentAllLocation',
  UPDATE_SHOP_CONFIG = 'updateShopConfig',
  UPDATE_QUALITY_CONFIG = 'updateQualityConfig',
  UPDATE_PROPERTY_AGE_CONFIG = 'updatePropertyAgeConfig',
  GET_All_IRE_UNIT_COMP = 'getAllIreUnitComp',
  GET_IRE_CONFIGS = 'getIreConfigs',
  GET_IRE_CONFIG = 'getIreConfig',
  UPDATE_IRE_CONFIG = 'updateIreConfig',
  UPDATE_IRE_CONFIG_CURRENT_PAGE = 'updateIreConfigCurrentPage',
  GET_IRE_INVESTMENT_PROPERTY_TYPES = 'getIreInvestmentPropertyTypes',
  GET_IRE_INVESTMENT_PROPERTY_AGES = 'getIreInvestmentPropertyAges',
  GET_IRE_INVESTMENT_PROPERTY_LOCATIONS = 'getIreInvestmentPropertyLocations',
  GET_IRE_INVESTMENT_QUALITIES = 'getIreInvestmentQualities',
  UPDATE_IRE_ENUMS = 'updateIreEnums',
  GET_IRE_ENUM = 'getIreENum',
  UPDATE_IRE_UNIT_COMP = 'updaeIreUnitComp',
  DELETE_IRE_INVESTMENT_PROPERTY_TYPES = 'deleteIreInvestmentPropertyTypes',
  GET_ADS_DASHBOARD = 'getAdsDashboard',
  GET_USERS_DASHBOARD = 'getUsersDashboard',
  GET_REGISTERED_TRADE_DASHBOARD = 'getRegisteredTradeDashboard',
  GET_AUCTIONS_DASHBOARD = 'getAuctionDashboard',
  GET_ESTIMATOR_DASHBOARD = 'getEstimatorDashboard',
  GET_ADS_REPORTS = 'getAdsReports',
  GET_USERS_REPORTS = 'getUsersReports',
  GET_REGISTERED_TRADE_REPORTS = 'getRegisteredTradeReports',
  GET_AUCTIONS_REPORTS = 'getAuctionReports',
  GET_ESTIMATOR_REPORTS = 'getEstimatorReports',
  GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID = 'getAllPropertyCategoriesByAdTypeId',
  GET_REGISTERED_TRADE_TOP_REPORTS = 'getRegisteredTradeTopReports',
  GET_REGISTERED_TRADE_TOTALS_REPORTS = 'getRegisteredTradeReport',
  GET_REGISTERED_TRADE_PER_QUARTER_REPORTS = 'getRegisteredTradePerQuarterReport',
  GET_REGISTERED_TRADE_TOP_AREAS_REPORTS = 'getRegisteredTradeRTopAreasReport',
  UPDATE_REGISTERED_TRADE_DASHBOARD_FILTER = 'updateRegisteredTradeDashboardFilter',
  UPDATE_AUCTIONS_DASHBOARD_FILTER = 'updateAuctionsDashboardFilter',
  UPDATE_ESTIMATOR_DASHBOARD_FILTER = 'updateEstimatorDashboardFilter',
  UPDATE_USERS_DASHBOARD_FILTER = 'updateUsersDashboardFilter',
  UPDATE_ADS_DASHBOARD_FILTER = 'updateAdsDashboardFilter',
  UPDATE_IVE_AREA_FILTER = 'updateIveAreaFilter',
  UPDATE_PROPERTY_TYPES_FILTER = 'updatePropertyTypesFilter',
  UPDATE_PROPERTY_CATEGORIES_FILTER = 'updatePropertyCategoriesFilter',
  GET_ALL_APP_USERS = 'getAllAppUsers',
  UPLOAD_ADS_IMAGES = 'uploadAdsImages',
  UPLOAD_ADS_VIDEO = 'uploadAdsVideo',
  UPLOAD_ADS_DOCUMENT = 'uploadAdsDocument',
  DELETE_AD_DOCUMENT = 'deleteAdDocument',
  UPDATE_IRE_V2_AREA_FILTER = 'updateIreV2AreaFilter',
  UPDATE_IRE_INVESTMENT_AREA_FILTER = 'updateIreInvestmentAreaFilter',
  CREATE_IRE_COMMON_AREA = 'createIreCommonArea',
  DELETE_IRE_COMMON_AREA = 'deleteIreCommonArea',
  UPDATE_IRE_COMMON_AREA = 'updateIreCommonArea',
  UPDATE_IRE_COMMON_AREA_CURRENT_PAGE = 'updateIreCommonAreaCurrentPage',
  GET_IRE_COMMON_AREA = 'getIreCommonArea',
  GET_IRE_COMMON_AREAS = 'getIreCommonAreas',
  GET_ALL_IRE_COMMON_AREAS = 'getAllIreCommonAreas',
  UPDATE_IRE_COMMON_AREA_FILTER = 'updateIreCommonAreaFilter',
  GET_IRE_COMMON_PROPERTY_TYPES = 'getIRECommonPropertyTypes',
  GET_IRE_COMMON_PROPERTY_AGES = 'getIRECommonProperty',
  GET_IRE_COMMON_QUALITIES = 'getIRECommonQuality',
  UPDATE_IRE_COMMON_ENUMS = 'updateIRECommonEnums',
  GET_IRE_COMMON_ENUM = 'getIRECommonEnum',
  DELETE_IRE_COMMON_PROPERTY_TYPES = 'deleteIRECommonProperty',
  UPDATE_IRE_COMMON_CONFIG_CURRENT_PAGE = 'updateIRECommonConfigCurrentPage',
  GET_IRE_COMMON_PROPERTY_DIRECTION = 'getIRECommonPropertyDirection',
  GET_IRE_COMMON_FLOOR_TYPES = 'getIRECommonFloorTypes',
  DELETE_IRE_COMMON_FLOOR_TYPES = 'deleteIREFloorTypes',
  CREATE_IRE_COMMON_STREET = 'createIreCommonStreet',
  DELETE_IRE_COMMON_STREET = 'deleteIreCommonStreet',
  UPDATE_IRE_COMMON_STREET = 'updateIreCommonStreet',
  UPDATE_IRE_COMMON_STREET_CONFIG = 'updateIreCommonStreetConfig',
  UPDATE_IRE_COMMON_STREET_CURRENT_PAGE = 'updateIreCommonStreetCurrentPage',
  GET_IRE_COMMON_STREET = 'getIreCommonStreet',
  GET_IRE_COMMON_STREETS = 'getIreCommonStreets',
  GET_ALL_IRE_COMMON_STREETS = 'getAllIreCommonStreets',
  UPDATE_IRE_COMMON_STREET_FILTER = 'updateIRECommonStreetsFilter',
  GET_IRE_COMMON_PROPERTY_DIRECTION_ALL = 'getIREPropertyDirectionsAll',
  GET_IRE_COMMON_FLOOR_TYPES_ALL = 'getIREPropertyFloorsAll',
  GET_IRE_COMMON_PROPERTY_TYPE_ALL = 'getIREPropertyTypesAll',
  UPDATE_FINANCES_FUNDS_REQUEST_CURRENT_PAGE = 'updateFinancedFundsRequestCurrentPage',
  GET_FINANCES_FUNDS_REQUESTS = 'getFinancesFundsRequest',
  GET_FINANCE_FUNDS_REQUEST = 'getFinanceFundsRequest',
  RESET_FINANCES_FUNDS_REQUEST_STORE = 'resetsFinanceFundsRequestStore',
  GET_AD_USER_INFO = 'getAdUserInfo',
  GET_ADS_APPRS_INFO = 'getAdsApprsInfo',
  GET_REGISTERED_TRADES_DESC = 'getRegisteredTradesDesc',
  CREATE_HOME_SERVICE = 'createHomeService',
  DELETE_HOME_SERVICE = 'deleteHomeService',
  UPDATE_HOME_SERVICE = 'updateHomeService',
  UPDATE_HOME_SERVICE_CURRENT_PAGE = 'updateHomeServiceCurrentPage',
  GET_HOME_SERVICE = 'getHomeService',
  GET_HOME_SERVICES = 'getHomeServices',
  GET_HOME_SERVICE_ASSETS = 'getHomeServiceAssets',
  UPLOAD_HOME_SERVICE_IMAGE = 'uploadHomeServiceLogo',
  RESET_HOME_SERVICES_STORE = 'resetHomeServicesStore',
  UPLOAD_HOME_SERVICE_IMAGE_WEB = 'uploadHomeServiceLogoWeb',
  GET_REGISTERED_TRADES_ASSETS = 'getRegisteredTradesAssetsAssets',
  EXPORT_FINANCES_FUNDS_REQUEST = 'exportFinancesFundsRequest',
  IMPORT_REGISTERED_TRADES = 'IMPORT_REGISTERED_TRADES',
  CLONE_AUCTION_PROPERTY = 'CLONE_AUCTION_PROPERTY',
  COPY_AUCTION_PROPERTY = 'COPY_AUCTION_PROPERTY',
  GET_REJECT_ADS_REASON = 'GET_REJECT_ADS_REASON',
  UPDATE_AREAS_GOV = 'UPDATE_AREAS_GOV',
  GET_AREAS_GOV_LIST = 'GET_AREAS_GOV_LIST',
  GET_ALL_GOV_LIST = 'GET_ALL_GOV_LIST',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_PASSWORD = 'setPassword',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_ADMIN_USERS = 'setAdminUsers',
  SET_APPRAISERS = 'setAppraisers',
  SET_ALL_PROPERTY_TYPES = 'setPropertyTypes',
  SET_ALL_PROPERTY_STATUS = 'setAllPropertyStatus',
  SET_USERS = 'setUsers',
  SET_PERMISSIONS = 'setPermissions',
  SET_ADMIN_CURRENT_PAGE = 'setAdminCurrentPage',
  SET_APPRAISER_CURRENT_PAGE = 'setAppraiserCurrentPage',
  SET_USER_CURRENT_PAGE = 'setUserCurrentPage',
  RESET_APPRAISALS_LIST = 'resetAppraisalsList',
  RESET_AUCTIONS_LIST = 'resetAuctionsList',
  RESET_ADMINS_LIST = 'resetAdminsList',
  RESET_APPRAISERS_LIST = 'resetAppraisersList',
  RESET_USERS_LIST = 'resetUsersList',
  RESET_PERMISSIONS_LIST = 'resetPermissionsList',
  RESET_FAQS_LIST = 'resetFaqsList',
  RESET_ABOUT_US = 'resetAboutUs',
  RESET_TERMS_AND_CONDITIONS = 'resetTermsAndConditions',
  RESET_BANK_RATES_LIST = 'resetBankRatesList',
  RESET_NEWS_LIST = 'restNewsList',
  SET_LOCALE = 'setLocale',
  SET_ROLES = 'setRoles',
  SET_BANKS = 'setBanks',
  SET_ANSWERS = 'setAnswers',
  SET_IVE_FACTOR_OPTIONS = 'setIveFactorOptions',
  RESET_IVE_AREA_ESTIMATIONS = 'resetIveFactorOptions',
  SET_IVE_AREA_ESTIMATIONS = 'setIveAreaEstimations',
  SET_COMPARE_IVE_AREA_ESTIMATIONS = 'setCompareIveAreaEstimations',
  SET_SECOND_COMPARE_IVE_AREA_ESTIMATIONS = 'setSecondCompareIveAreaEstimations',
  SET_AUCTION_PROPERTY_TYPES = 'setAuctionPropertyTypes',
  SET_LOCATIONS = 'setLocations',
  SET_PROPERTIES = 'setProperties',
  SET_AREAS = 'setAreas',
  SET_IVE_AREAS = 'setIveAreas',
  SET_ALL_IVE_AREAS = 'setAllIveAreas',
  SET_PROPERTY_TYPES = 'setPropertyTypes',
  SET_ORGANIZERS = 'setOrganizers',
  SET_ORGANIZER_LOCATIONS = 'setOrganizerLocations',
  SET_ROLES_GROUPS = 'setRolesGroups',
  SET_ROLE_CURRENT_PAGE = 'setRoleCurrentPage',
  SET_BANK_CURRENT_PAGE = 'setBankCurrentPage',
  SET_ANSWERS_CURRENT_PAGE = 'setAnswersCurrentPage',
  SET_IVE_FACTOR_OPTIONS_CURRENT_PAGE = 'setFactorOptionsCurrentPage',
  SET_AUCTION_PROPERTY_TYPE_CURRENT_PAGE = 'setAuctionPropertyTypeCurrentPage',
  SET_ALL_AUCTION_PROPERTY_TYPES = 'setAllAuctionPropertyTypes',
  SET_LOCATION_CURRENT_PAGE = 'setLocationCurrentPage',
  SET_ORGANIZER_CURRENT_PAGE = 'setOrganizerCurrentPage',
  SET_AD_TYPE_CURRENT_PAGE = 'setAdTypeCurrentPage',
  SET_FAQ_CURRENT_PAGE = 'setFaqCurrentPage',
  SET_PROPERTY_CURRENT_PAGE = 'setPropertyCurrentPage',
  SET_AREA_CURRENT_PAGE = 'setAreaCurrentPage',
  SET_IVE_AREA_CURRENT_PAGE = 'setIveAreaCurrentPage',
  SET_PROPERTY_TYPE_CURRENT_PAGE = 'setPropertyTypeCurrentPage',
  SET_QUALITY_RANGES_CURRENT_PAGE = 'setQualityRangesCurrentPage',
  SET_QUALITY_GROUPS_CURRENT_PAGE = 'setQualityGroupsCurrentPage',
  SET_QUALITY_RANGES = 'setQualityRanges',
  SET_QUALITY_GROUPS = 'setQualityGroups',
  RESET_QUALITY_RANGES_LIST = 'resetQualityRangesList',
  RESET_QUALITY_GROUPS_LIST = 'resetQualityGroupsList',
  SET_QUESTIONS = 'setQuestions',
  RESET_QUESTIONS = 'resetQuestions',
  RESET_ROLES_LIST = 'resetRolesList',
  RESET_BANKS_LIST = 'resetBanksList',
  RESET_ANSWERS_LIST = 'resetAnswersList',
  RESET_IVE_FACTOR_OPTIONS_LIST = 'resetFactorOptionsList',
  RESET_AUCTION_PROPERTY_TYPES_LIST = 'resetAuctionPropertyTypesList',
  RESET_LOCATIONS_LIST = 'resetLocationsList',
  RESET_ORGANIZERS_LIST = 'resetOrganizersList',
  RESET_AD_TYPES_LIST = 'resetAdTypeList',
  RESET_PROPERTIES_LIST = 'restPropertiesList',
  RESET_AREAS_LIST = 'restAreasList',
  RESET_IVE_AREAS_LIST = 'restIveAreasList',
  RESET_PROPERTY_TYPES_LIST = 'restPropertyTypesList',
  SET_APPRAISALS = 'setAppraisals',
  SET_AUCTIONS = 'setAuctions',
  SET_AUCTION_PROPERTIES = 'setAuctionProperties',
  SET_AUCTION_STATUSES = 'setAuctionStatuses',
  SET_AUCTION_AREAS = 'setAuctionAreas',
  SET_APPRAISALS_CURRENT_PAGE = 'setAppraisalsCurrentPage',
  SET_AUCTIONS_CURRENT_PAGE = 'setAuctionsCurrentPage',
  SET_AD_TYPES = 'setAdTypes',
  SET_FAQS = 'setFaqs',
  SET_CONTACTS = 'setContact',
  SET_LOANS_FUNDS_REQUEST = 'setLoans',
  SET_LOANS_FUNDS_REQUEST_CURRENT_PAGE = 'setLoansCurrentPage',
  RESET_LOANS_FUNDS_REQUEST_LIST = 'resetLoansFundsRequestList',
  SET_CONTACT_CURRENT_PAGE = 'setContactCurrentPage',
  SET_CCE_GROUPS = 'setCceGroups',
  SET_CCE_GROUP_CURRENT_PAGE = 'setCceGroupCurrentPage',
  RESET_CCE_GROUPS_LIST = 'resetCceGroupsList',
  SET_CCE_CATEGORIES = 'setCceCategories',
  SET_BANK_RATES = 'setBankRates',
  SET_NEWS = 'setNews',
  SET_BANK_RATE_CURRENT_PAGE = 'setBankRateCurrentPage',
  SET_NEWS_CURRENT_PAGE = 'setNewsCurrentPage',
  SET_CCE_CATEGORIE_CURRENT_PAGE = 'setCceCategorieCurrentPage',
  RESET_CCE_CATEGORIES_LIST = 'resetCceCategoriesList',
  SET_ALL_CCE_GROUPS = 'setAllCceSubGroups',
  SET_CCE_SUB_CATEGORIES = 'setCceSubCategories',
  SET_CCE_SUB_CATEGORIE_CURRENT_PAGE = 'setCceSubCategorieCurrentPage',
  RESET_CCE_SUB_CATEGORIES_LIST = 'resetCceSubCategoriesList',
  SET_ALL_CCE_CATEGORIES = 'setAllCceCategories',
  SET_ALL_CCE_QUALITY = 'setAllCceQuality',
  RESET_REGISTERED_TRADES_LIST = 'resetRegisteredTradesList',
  SET_REGISTERED_TRADES = 'setRegisteredTrades',
  SET_REGISTERED_TRADES_CURRENT_PAGE = 'setRegisteredTradesCurrentPage',
  RESET_REGISTERED_TRADES_SCRAPING_LIST = 'resetRegisteredTradesScrapingList',
  SET_REGISTERED_TRADES_SCRAPING = 'setRegisteredTradesScraping',
  SET_REGISTERED_TRADES_SCRAPING_CURRENT_PAGE = 'setRegisteredTradesScrapingCurrentPage',
  SET_ADS = 'setAds',
  SET_AD_CURRENT_PAGE = 'setAdCurrentPage',
  RESET_ADS_LIST = 'resetAdsList',
  SET_AD_FILTER = 'setAdFilter',
  SET_ADS_PACKAGES = 'setAdsPackages',
  SET_REGISTERED_TRADES_FILTER = 'setRegisteredTradesFilter',
  SET_ABOUT_US = 'setAboutUs',
  SET_TERMS_AND_CONDITIONS = 'setTermsAndConditions',
  SET_NON_IVE_AREAS = 'setNonIveAreasList',
  SET_ADS_SERVICES = 'setAdsServices',
  SET_ADS_SERVICES_CURRENT_PAGE = 'setAdsServicesCurrentPage',
  RESET_ADS_SERVICE_LIST = 'resetAdsServiceList',
  RESET_ADS_PACKAGE_LIST = 'resetAdsPackateList',
  SET_ADS_PACKAGES_CURRENT_PAGE = 'setAdsPackageCurrentPage',
  SET_BANNERS = 'setBanners',
  SET_BANNERS_CURRENT_PAGE = 'setBannersCurrentPage',
  RESET_BANNERS_LIST = 'resetBannersList',
  SET_BANNERS_ASSETS = 'setBannersAssets',
  SET_MERCHANT = 'setMerchants',
  SET_AUCTION_ORGANISE = 'setAuctionOrganise',
  SET_AUCTIONS_FILTER = 'setAuctionsFilter',
  RESET_IRE_AREAS_LIST = 'resetIreAreasList',
  SET_IRE_AREA_CURRENT_PAGE = 'setIreAreaCurrentPage',
  SET_IRE_AREAS = 'setIreAreas',
  SET_ALL_IRE_AREAS = 'setAllIreAreas',
  SET_IRE_FACTORS = 'setIreFactors',
  SET_FACTORS = 'setFactors',
  SET_IRE_FACTOR_AREAS_VALUES = 'setIreFactorAreasValues',
  SET_IRE_FACTOR_CURRENT_PAGE = 'setIreFactorCurrentPage',
  RESET_IRE_FACTORS_LIST = 'resetIreFactorsList',
  SET_IRE_TYPES = 'setIreType',
  SET_ALL_IRE_TYPES = 'setAllIreTypes',
  SET_IRE_TYPE_CURRENT_PAGE = 'setIreTypeCurrentPage',
  RESET_IRE_TYPES_LIST = 'resetIreTypesList',
  SET_IRE_SIZES = 'setIreSize',
  SET_SIZES = 'setSizes',
  SET_IRE_SIZE_AREAS_VALUES = 'setIreSizeAreasValues',
  SET_IRE_SIZE_CURRENT_PAGE = 'setIreSizeCurrentPage',
  RESET_IRE_SIZES_LIST = 'resetIreSizesList',
  SET_EXPORT_IVE_AREAS = 'setExportIveAreas',
  SET_HEADERS = 'setHeaderSection',
  SET_HEADER_SECTION_CURRENT_PAGE = 'setHeaderSectionCurrentPage',
  RESET_HEADER_LIST = 'resetHeaderList',
  SET_DISCLAIMERS = 'setDisclaimers',
  RESET_DISCLAIMER_LIST = 'resetDisclaimerList',
  SET_DISCLAIMER_CURRENT_PAGE = 'setDisclaimerCurrentPage',
  SET_BANK_RATE_LOG_REPORTS = 'setBankRateLogReports',
  SET_EXPORT_RATES_LOGS = 'setExportRatesLogs',
  SET_BANK_RATES_FILTER = 'setBankRatesFilter',
  RESET_AUCTIONS_PROPERTY = 'resetAuctionsProperty',
  SET_IRE_V2_FACTORS = 'setIreV2Factors',
  SET_IRE_V2_FACTOR_CURRENT_PAGE = 'setIreV2FactorCurrentPage',
  RESET_IRE_V2_FACTORS_LIST = 'resetIreV2FactorsList',
  SET_IRE_V2_FACTOR_AREAS_VALUES = 'setIreV2FactorAreasValues',
  SET_IRE_V2_AREAS = 'setIreV2Areas',
  SET_ALL_IRE_V2_AREAS = 'setAllIreV2Areas',
  SET_IRE_V2_AREA_CURRENT_PAGE = 'setIreV2AreaCurrentPage',
  RESET_IRE_V2_AREAS_LIST = 'resetIreV2AreasList',
  SET_ALL_IRE_V2_SIZE = 'setAllIreV2Size',
  SET_ALL_IRE_V2_PROPERTY_AGE = 'setAllIreV2PropertyAge',
  SET_ALL_IRE_V2_SIZE_DESC = 'setAllIreV2SizeDesc',
  SET_PROPERTY_CATEGORIES = 'setPropertyCategories',
  SET_PROPERTY_CATEGORY_CURRENT_PAGE = 'setPropertyCategoryCurrentPage',
  RESET_PROPERTY_CATEGORIES_LIST = 'resetPropertyCategoriesList',
  SET_ALL_PROPERTY_CATEGORIES = 'setAllPropertyCategories',
  SET_ALL_AD_TYPES = 'setAllAdTypes',
  SET_IRE_INVESTMENT_AREAS = 'setIreInvestmentAreas',
  SET_ALL_IRE_INVESTMENT_AREAS = 'setAllIreInvestmentAreas',
  SET_IRE_INVESTMENT_AREA_CURRENT_PAGE = 'setIreInvestmentAreaCurrentPage',
  RESET_IRE_INVESTMENT_AREAS_LIST = 'resetIreInvestmentAreasList',
  SET_ALL_IRE_UNIT_COMP = 'setAllIreUnitComp',
  SET_IRE_CONFIGS = 'setIreConfig',
  RESET_IRE_CONFIG_LIST = 'resetIREConfigList',
  SET_IRE_CONFIG_CURRENT_PAGE = 'setIREConfigCurrentPage',
  SET_IRE_INVESTMENT_PROPERTY_LOCATIONS = 'setIreInvestmentPropertyLocations',
  SET_IRE_INVESTMENT_PROPERTY_AGE = 'setIREInvestmentPropertyAge',
  SET_IRE_INVESTMENT_PROPERTY_TYPE = 'setIREInvestmentPropertyType',
  SET_IRE_INVESTMENT_QUALITIES = 'setIREInvestmentQualities',
  SET_REGISTERED_TRADE_DASHBOARD = 'setRegisteredTradeDashboard',
  SET_AUCTIONS_DASHBOARD = 'setAuctionsDashboard',
  SET_ESTIMATOR_DASHBOARD = 'setEstimatorDashboard',
  SET_USERS_DASHBOARD = 'setUsersDashboard',
  SET_ADS_DASHBOARD = 'serAdsDashboard',
  SET_REGISTERED_TRADE_REPORTS = 'setRegisteredTradeReports',
  SET_AUCTIONS_REPORTS = 'setAuctionsReports',
  SET_ESTIMATOR_REPORTS = 'setEstimatorReports',
  SET_USERS_REPORTS = 'setUsersReports',
  SET_ADS_REPORTS = 'serAdsReports',
  SET_REGISTERED_TRADE_TOP_REPORTS = 'setRegisteredTradeTopReports',
  SET_REGISTERED_TRADE_PER_QUARTER_REPORTS = 'setRegisteredTradePerQuarterReport',
  SET_REGISTERED_TRADE_TOTALS_REPORTS = 'setRegisteredTradeTotalsReport',
  SET_REGISTERED_TRADE_TOP_AREAS_REPORTS = 'setRegisteredTradeTopAreasReport',
  SET_REGISTERED_TRADES_DASHBOARD_FILTER = 'setRegisteredTradeDashboardFilter',
  SET_AUCTIONS_DASHBOARD_FILTER = 'setAuctionsDashboardFilter',
  SET_ESTIMATOR_DASHBOARD_FILTER = 'setEstimatorDashboardFilter',
  SET_USERS_DASHBOARD_FILTER = 'setUsersDashboardFilter',
  SET_ADS_DASHBOARD_FILTER = 'setAdsDashboardFilter',
  SET_IVE_AREA_FILTER = 'setIveAreaFilter',
  SET_PROPERTY_TYPES_FILTER = 'setPropertyTypesFilter',
  SET_PROPERTY_CATEGORIES_FILTER = 'setPropertyCategoriesFilter',
  SET_ALL_APP_USERS = 'setAllAppUsers',
  SET_IRE_INVESTMENT_AREA_FILTER = 'setIreInvestmentAreaFilter',
  SET_IRE_V2_AREA_FILTER = 'setIREV2AreaFilter',
  SET_IRE_COMMON_AREAS = 'setIreCommonAreas',
  SET_ALL_IRE_COMMON_AREAS = 'setAllIreCommonAreas',
  SET_IRE_COMMON_AREA_CURRENT_PAGE = 'setIreCommonAreaCurrentPage',
  RESET_IRE_COMMON_AREAS_LIST = 'resetIreCommonAreasList',
  SET_IRE_COMMON_AREA_FILTER = 'setIreCommonAreaFilter',
  RESET_IRE_COMMON_CONFIG_LIST = 'resetIreCommonConfig',
  SET_IRE_COMMON_QUALITIES = 'setIreCommonQuality',
  SET_IRE_COMMON_PROPERTY_AGE = 'setIreCommonPropertyAge',
  SET_IRE_COMMON_PROPERTY_TYPE = 'setIreCommonPropertyType',
  SET_IRE_COMMON_CONFIG_CURRENT_PAGE = 'serIreCommonConfigCurrentPage',
  SET_IRE_COMMON_PROPERTY_DIRECTION = 'serIreCommonPropertyDirection',
  SET_IRE_COMMON_FLOOR_TYPE = 'setIreCommonFloorType',
  SET_IRE_COMMON_STREETS = 'setIreInvestmentAreas',
  SET_ALL_IRE_COMMON_STREETS = 'setAllIreCommonStreets',
  SET_IRE_COMMON_STREET_CURRENT_PAGE = 'setIreCommonStreetCurrentPage',
  RESET_IRE_COMMON_STREET_LIST = 'resetIreCommonStreetsList',
  RESET_IRE_COMMON_STREETS_LIST = 'setIreCommonStreetsList',
  SET_IRE_COMMON_STREET_FILTER = 'setIreCommonStreetsFilter',
  SET_IRE_COMMON_PROPERTY_TYPE_ALL = 'setIRECommonPropertyTypeAll',
  SET_IRE_COMMON_FLOOR_TYPES_ALL = 'setIRECommonFloorTypesAll',
  SET_IRE_COMMON_PROPERTY_DIRECTION_ALL = 'setIRECommonPropertyDirectionsAll',
  SET_FINANCES_FUNDS_REQUEST = 'setFinances',
  SET_FINANCES_FUNDS_REQUEST_CURRENT_PAGE = 'setFinancesCurrentPage',
  RESET_FINANCES_FUNDS_REQUEST_LIST = 'resetFinancesFundsRequestList',
  SET_REGISTERED_TRADES_DESC = 'setRegisteredDesc',
  SET_HOME_SERVICES = 'setHomeServices',
  SET_HOME_SERVICES_CURRENT_PAGE = 'setHomeServicesCurrentPage',
  RESET_HOME_SERVICES_LIST = 'resetHomeServicessList',
  SET_REGISTERED_TRADES_ASSEST_DESC = 'setRegisteredTradesAssestDesc',
  SET_REGISTERED_TRADES_ASSEST_NOTES = 'setRegisteredTradesAssestNotes',
  SET_EXPORT_FINANCES_FUNDS_REQUEST = 'setExportFinancesFundsRequest',
  SET_COUNTS = 'setCounts',
  SET_GOV_AREAS = 'SET_GOV_AREAS',
  SET_ALL_GOV = 'SET_ALL_GOV',
}

export { Actions, Mutations };
