import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Mutations, Actions } from '@/store/enums/StoreEnums';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/sign-in',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/manage/reports/reports-registered-trades',
        name: 'registered-trades-reports',
        component: () =>
          import('@/views/manage/reports/registeredTradesReports.vue'),
      },
      {
        path: '/manage/reports/reports-ads-quarter',
        name: 'ads-quarter-reports',
        component: () =>
          import('@/views/manage/reports/AdsPerQuarterReports.vue'),
      },
      {
        path: '/manage/reports/top-reports-registered-trades',
        name: 'registered-trades-top-reports',
        component: () =>
          import('@/views/manage/reports/RegisteredTradesTopReports.vue'),
      },
      {
        path: '/manage/reports/reports-registered-trades-quarter',
        name: 'registered-trades-per-quarter-reports',
        component: () =>
          import(
            '@/views/manage/reports/RegisteredTradesPerQuarterReports.vue'
          ),
      },
      {
        path: '/manage/reports/reports-registered-trades-totals',
        name: 'registered-trades-totals-reports',
        component: () =>
          import('@/views/manage/reports/RegisteredTradesTotalsReports.vue'),
      },
      {
        path: '/manage/reports/reports-registered-trades-areas',
        name: 'registered-trades-top-areas-reports',
        component: () =>
          import('@/views/manage/reports/RegisteredTradesTopAreasReports.vue'),
      },
      {
        path: '/manage/members/admins-listing',
        name: 'admins-listing',
        component: () =>
          import('@/views/manage/members/admins/AdminsListing.vue'),
      },
      {
        path: '/manage/members/admins-creating',
        name: 'admins-creating',
        component: () =>
          import('@/views/manage/members/admins/AdminsCreating.vue'),
      },
      {
        path: '/manage/members/admins-editing/:id',
        name: 'admins-editing',
        component: () =>
          import('@/views/manage/members/admins/AdminsEditing.vue'),
      },
      {
        path: '/manage/members/users-listing',
        name: 'users-listing',
        component: () =>
          import('@/views/manage/members/users/UsersListing.vue'),
      },
      {
        path: '/manage/members/roles-listing',
        name: 'roles-listing',
        component: () =>
          import('@/views/manage/members/roles/RolesListing.vue'),
      },
      {
        path: '/manage/members/roles-creating',
        name: 'roles-creating',
        component: () =>
          import('@/views/manage/members/roles/RolesCreating.vue'),
      },
      {
        path: '/manage/members/roles-editing/:id',
        name: 'roles-editing',
        component: () =>
          import('@/views/manage/members/roles/RolesEditing.vue'),
      },
      {
        path: '/manage/appraisals/appraisals-listing',
        name: 'appraisals-listing',
        component: () =>
          import(
            '@/views/manage/appraisals/appraisals/admin/AppraisalsListing.vue'
          ),
      },
      {
        path: '/manage/appraisals/appraisals-viewing/:id',
        name: 'appraisals-viewing',
        component: () =>
          import(
            '@/views/manage/appraisals/appraisals/admin/AppraisalsOverview.vue'
          ),
      },
      {
        path: '/manage/appraisals/appraisals-editing/:id',
        name: 'appraisals-editing',
        component: () =>
          import(
            '@/views/manage/appraisals/appraisals/admin/AppraisalsEditing.vue'
          ),
      },
      {
        path: '/manage/appraisals/appraiser-appraisals-listing',
        name: 'appraiser-appraisals-listing',
        component: () =>
          import(
            '@/views/manage/appraisals/appraisals/appraiser/AppraisalsListing.vue'
          ),
      },
      {
        path: '/manage/appraisals/appraiser-appraisals-viewing/:id',
        name: 'appraiser-appraisals-viewing',
        component: () =>
          import(
            '@/views/manage/appraisals/appraisals/appraiser/AppraisalsOverview.vue'
          ),
      },
      {
        path: '/manage/appraisals/appraiser-appraisals-editing/:id',
        name: 'appraiser-appraisals-editing',
        component: () =>
          import(
            '@/views/manage/appraisals/appraisals/appraiser/AppraisalsEditing.vue'
          ),
      },
      {
        path: '/manage/members/banks-listing',
        name: 'banks-listing',
        component: () =>
          import('@/views/manage/members/banks/BanksListing.vue'),
      },
      {
        path: '/manage/members/banks-creating',
        name: 'banks-creating',
        component: () =>
          import('@/views/manage/members/banks/BanksCreating.vue'),
      },
      {
        path: '/manage/members/banks-editing/:id',
        name: 'banks-editing',
        component: () =>
          import('@/views/manage/members/banks/BanksEditing.vue'),
      },
      {
        path: '/manage/members/appraisers-listing',
        name: 'appraisers-listing',
        component: () =>
          import('@/views/manage/members/appraisers/AppraisersListing.vue'),
      },
      {
        path: '/manage/members/appraisers-creating',
        name: 'appraisers-creating',
        component: () =>
          import('@/views/manage/members/appraisers/AppraisersCreating.vue'),
      },
      {
        path: '/manage/members/appraisers-editing/:id',
        name: 'appraisers-editing',
        component: () =>
          import('@/views/manage/members/appraisers/AppraisersEditing.vue'),
      },
      {
        path: '/manage/ads/ad-types-listing',
        name: 'ad-types-listing',
        component: () =>
          import('@/views/manage/ads/ad-types/AdTypesListing.vue'),
      },
      {
        path: '/manage/ads/ad-types-creating',
        name: 'ad-types-creating',
        component: () =>
          import('@/views/manage/ads/ad-types/AdTypesCreating.vue'),
      },
      {
        path: '/manage/ads/ad-types-editing/:id',
        name: 'ad-types-editing',
        component: () =>
          import('@/views/manage/ads/ad-types/AdTypesEditing.vue'),
      },
      {
        path: '/manage/appraisals/properties-listing',
        name: 'properties-listing',
        component: () =>
          import('@/views/manage/appraisals/properties/PropertiesListing.vue'),
      },
      {
        path: '/manage/appraisals/properties-creating',
        name: 'properties-creating',
        component: () =>
          import('@/views/manage/appraisals/properties/PropertiesCreating.vue'),
      },
      {
        path: '/manage/appraisals/properties-editing/:id',
        name: 'properties-editing',
        component: () =>
          import('@/views/manage/appraisals/properties/PropertiesEditing.vue'),
      },
      {
        path: '/manage/assets/areas-listing',
        name: 'areas-listing',
        component: () => import('@/views/manage/assets/areas/AreasListing.vue'),
      },
      {
        path: '/manage/assets/areas-editing/:id',
        name: 'areas-editing',
        component: () => import('@/views/manage/assets/areas/AreasEditing.vue'),
      },
      {
        path: '/manage/assets/areas-creating',
        name: 'areas-creating',
        component: () =>
          import('@/views/manage/assets/areas/AreasCreating.vue'),
      },
      {
        path: '/manage/assets/gov-editing',
        name: 'gov-editing',
        component: () => import('@/views/manage/assets/GovEditing.vue'),
      },
      {
        path: '/manage/ads/property-types-listing',
        name: 'property-types-listing',
        component: () =>
          import('@/views/manage/ads/property-types/PropertyTypesListing.vue'),
      },
      {
        path: '/manage/ads/property-types-creating',
        name: 'property-types-creating',
        component: () =>
          import('@/views/manage/ads/property-types/PropertyTypesCreating.vue'),
      },
      {
        path: '/manage/ads/property-types-editing/:id',
        name: 'property-types-editing',
        component: () =>
          import('@/views/manage/ads/property-types/PropertyTypesEditing.vue'),
      },
      {
        path: '/manage/cce/cce-groups-listing',
        name: 'cce-groups-listing',
        component: () =>
          import('@/views/manage/CCE/groups/CceGroupsListing.vue'),
      },
      {
        path: '/manage/cce/cce-groups-creating',
        name: 'cce-groups-creating',
        component: () =>
          import('@/views/manage/CCE/groups/CceGroupsCreating.vue'),
      },
      {
        path: '/manage/cce/cce-groups-editing/:id',
        name: 'cce-groups-editing',
        component: () =>
          import('@/views/manage/CCE/groups/CceGroupsEditing.vue'),
      },
      {
        path: '/manage/cce/cce-categories-listing',
        name: 'cce-categories-listing',
        component: () =>
          import('@/views/manage/CCE/categories/CceCategoriesListing.vue'),
      },
      {
        path: '/manage/cce/cce-categories-creating',
        name: 'cce-categories-creating',
        component: () =>
          import('@/views/manage/CCE/categories/CceCategoriesCreating.vue'),
      },
      {
        path: '/manage/cce/cce-categories-editing/:id',
        name: 'cce-categories-editing',
        component: () =>
          import('@/views/manage/CCE/categories/CceCategoriesEditing.vue'),
      },

      {
        path: '/manage/cce/cce-sub-categories-listing',
        name: 'cce-sub-categories-listing',
        component: () =>
          import(
            '@/views/manage/CCE/sub-categories/CceSubCategoriesListing.vue'
          ),
      },
      {
        path: '/manage/cce/cce-sub-categories-creating',
        name: 'cce-sub-categories-creating',
        component: () =>
          import(
            '@/views/manage/CCE/sub-categories/CceSubCategoriesCreating.vue'
          ),
      },
      {
        path: '/manage/cce/cce-sub-categories-editing/:id',
        name: 'cce-sub-categories-editing',
        component: () =>
          import(
            '@/views/manage/CCE/sub-categories/CceSubCategoriesEditing.vue'
          ),
      },
      {
        path: '/manage/auctions/auctions-listing',
        name: 'auctions-listing',
        component: () => import('@/views/manage/auctions/AuctionsListing.vue'),
      },
      {
        path: '/manage/auctions/auctions-creating',
        name: 'auctions-creating',
        component: () => import('@/views/manage/auctions/AuctionsCreating.vue'),
      },
      {
        path: '/manage/auctions/auctions-editing/:id',
        name: 'auctions-editing',
        component: () => import('@/views/manage/auctions/AuctionsEditing.vue'),
      },
      {
        path: '/manage/settings/about-us-overview',
        name: 'about-us-overview',
        component: () =>
          import('@/views/settings/about-us/AboutUsOverview.vue'),
      },
      {
        path: '/manage/settings/about-us-editing',
        name: 'about-us-editing',
        component: () => import('@/views/settings/about-us/AboutUsEditing.vue'),
      },
      {
        path: '/manage/settings/terms-conditions-overview',
        name: 'terms-conditions-overview',
        component: () =>
          import(
            '@/views/settings/terms-conditions/TermsConditionsOverview.vue'
          ),
      },
      {
        path: '/manage/settings/terms-conditions-editing',
        name: 'terms-conditions-editing',
        component: () =>
          import(
            '@/views/settings/terms-conditions/TermsConditionsEditing.vue'
          ),
      },
      {
        path: '/manage/settings/faqs-listing',
        name: 'faqs-listing',
        component: () => import('@/views/settings/faqs/FaqsListing.vue'),
      },
      {
        path: '/manage/settings/faqs-creating',
        name: 'faqs-creating',
        component: () => import('@/views/settings/faqs/FaqsCreating.vue'),
      },
      {
        path: '/manage/settings/faqs-editing/:id',
        name: 'faqs-editing',
        component: () => import('@/views/settings/faqs/FaqsEditing.vue'),
      },
      {
        path: '/manage/settings/contact-us-listing',
        name: 'contact-us-listing',
        component: () =>
          import('@/views/settings/contact-us/ContactUsListing.vue'),
      },
      {
        path: '/manage/settings/contact-us-overview/:id',
        name: 'contact-us-overview',
        component: () =>
          import('@/views/settings/contact-us/ContactUsOverView.vue'),
      },
      {
        path: '/manage/settings/header-section-listing',
        name: 'header-section-listing',
        component: () =>
          import('@/views/settings/header-section/HeaderSectionListing.vue'),
      },
      {
        path: '/manage/settings/header-section-editing/:id',
        name: 'header-section-editing',
        component: () =>
          import('@/views/settings/header-section/HeaderSectionEditing.vue'),
      },
      {
        path: '/manage/settings/disclaimer-listing',
        name: 'disclaimer-listing',
        component: () =>
          import('@/views/settings/disclaimer/DisclaimerListing.vue'),
      },
      {
        path: '/manage/settings/disclaimer-editing/:id',
        name: 'disclaimer-editing',
        component: () =>
          import('@/views/settings/disclaimer/DisclaimerEditing.vue'),
      },

      {
        path: '/manage/auctions/locations-listing',
        name: 'locations-listing',
        component: () =>
          import('@/views/manage/auctions/locations/LocationsListing.vue'),
      },
      {
        path: '/manage/auctions/locations-creating',
        name: 'locations-creating',
        component: () =>
          import('@/views/manage/auctions/locations/LocationsCreating.vue'),
      },
      {
        path: '/manage/auctions/locations-editing/:id',
        name: 'locations-editing',
        component: () =>
          import('@/views/manage/auctions/locations/LocationsEditing.vue'),
      },
      {
        path: '/manage/auctions/property-types-listing',
        name: 'auction-property-types-listing',
        component: () =>
          import(
            '@/views/manage/auctions/property-types/PropertyTypesListing.vue'
          ),
      },
      {
        path: '/manage/auctions/property-types-creating',
        name: 'auction-property-types-creating',
        component: () =>
          import(
            '@/views/manage/auctions/property-types/PropertyTypesCreating.vue'
          ),
      },
      {
        path: '/manage/auctions/property-types-editing/:id',
        name: 'auction-property-types-editing',
        component: () =>
          import(
            '@/views/manage/auctions/property-types/PropertyTypesEditing.vue'
          ),
      },
      {
        path: '/manage/auctions/organizers-listing',
        name: 'organizers-listing',
        component: () =>
          import('@/views/manage/auctions/organizers/OrganizersListing.vue'),
      },
      {
        path: '/manage/auctions/organizers-creating',
        name: 'organizers-creating',
        component: () =>
          import('@/views/manage/auctions/organizers/OrganizersCreating.vue'),
      },
      {
        path: '/manage/auctions/organizers-editing/:id',
        name: 'organizers-editing',
        component: () =>
          import('@/views/manage/auctions/organizers/OrganizersEditing.vue'),
      },
      {
        path: '/manage/registered-trades/registered-trades-listing',
        name: 'registered-trades-listing',
        component: () =>
          import(
            '@/views/manage/registered-trades/RegisteredTradesListing.vue'
          ),
      },
      {
        path: '/manage/registered-trades/registered-trades-creating',
        name: 'registered-trades-creating',
        component: () =>
          import(
            '@/views/manage/registered-trades/RegisteredTradesCreating.vue'
          ),
      },
      {
        path: '/manage/registered-trades/registered-trades-editing/:id',
        name: 'registered-trades-editing',
        component: () =>
          import(
            '@/views/manage/registered-trades/RegisteredTradesEditing.vue'
          ),
      },
      {
        path: '/manage/registered-trades/registered-trades-scraping-listing',
        name: 'registered-trades-scraping-listing',
        component: () =>
          import(
            '@/views/manage/registered-trades-scraping/RegisteredTradesScrapingListing.vue'
          ),
      },
      {
        path: '/manage/registered-trades/registered-trades-scraping-creating',
        name: 'registered-trades-scraping-creating',
        component: () =>
          import(
            '@/views/manage/registered-trades-scraping/RegisteredTradesScrapingCreating.vue'
          ),
      },
      {
        path: '/manage/registered-trades/registered-trades-scraping-editing/:id',
        name: 'registered-trades-scraping-editing',
        component: () =>
          import(
            '@/views/manage/registered-trades-scraping/RegisteredTradesScrapingEditing.vue'
          ),
      },
      {
        path: '/manage/ads/ads-listing',
        name: 'ads-listing',
        component: () => import('@/views/manage/ads/AdsListing.vue'),
      },
      {
        path: '/manage/ads/ads-viewing/:id',
        name: 'ads-viewing',
        component: () => import('@/views/manage/ads/AdsOverview.vue'),
      },
      {
        path: '/manage/ads/ads-editing/:id',
        name: 'ads-editing',
        component: () => import('@/views/manage/ads/AdsEditing.vue'),
      },
      {
        path: '/manage/ads/ads-updating/:id',
        name: 'ads-updating',
        component: () => import('@/views/manage/ads/AdsUpdating.vue'),
      },
      {
        path: '/manage/ads/ads-creating',
        name: 'ads-creating',
        component: () => import('@/views/manage/ads/AdsCreating.vue'),
      },
      {
        path: '/manage/ive/finishing-quality/questions/:questionId/answers-listing',
        name: 'ive-answers-listing',
        component: () =>
          import(
            '@/views/manage/IVE/finishing-quality/answers/AnswersListing.vue'
          ),
      },
      {
        path: '/manage/ive/finishing-quality/questions/:questionId/answers-creating',
        name: 'ive-answers-creating',
        component: () =>
          import(
            '@/views/manage/IVE/finishing-quality/answers/AnswersCreating.vue'
          ),
      },
      {
        path: '/manage/ive/finishing-quality/questions/answers-editing/:id',
        name: 'ive-answers-editing',
        component: () =>
          import(
            '@/views/manage/IVE/finishing-quality/answers/AnswersEditing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/street-creating',
        name: 'street-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/street/StreetsCreating.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/street-editing/:id',
        name: 'street-options-editing',
        component: () =>
          import('@/views/manage/IVE/factor-options/street/StreetsEditing.vue'),
      },
      {
        path: '/manage/ive/factor-options/street-listing',
        name: 'street-options-listing',
        component: () =>
          import('@/views/manage/IVE/factor-options/street/StreetsListing.vue'),
      },
      {
        path: '/manage/ive/factor-options/land-size-listing',
        name: 'land-size-options-listing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/land-sizes/LandSizesListing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/land-size-creating',
        name: 'land-size-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/land-sizes/LandSizesCreating.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/land-size-editing/:id',
        name: 'land-size-options-editing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/land-sizes/LandSizesEditing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/direction-listing',
        name: 'direction-options-listing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/direction/DirectionsListing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/direction-creating',
        name: 'direction-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/direction/DirectionsCreating.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/direction-editing/:id',
        name: 'direction-options-editing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/direction/DirectionsEditing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/plot-specs-listing',
        name: 'plot-specs-options-listing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/plot-specs/PlotSpecsListing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/plot-specs-creating',
        name: 'plot-specs-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/plot-specs/PlotSpecsCreating.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/plot-specs-editing/:id',
        name: 'plot-specs-options-editing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/plot-specs/PlotSpecsEditing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/location-specs-listing',
        name: 'location-specs-options-listing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/location-specs/LocationSpecsListing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/location-specs-editing/:id',
        name: 'location-specs-options-editing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/location-specs/LocationSpecsEditing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/location-specs-creating',
        name: 'location-specs-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/location-specs/LocationSpecsCreating.vue'
          ),
      },
      {
        path: '/manage/ive/estimator/land-listing',
        name: 'land-estimator-listing',
        component: () =>
          import('@/views/manage/IVE/estimator/land/EstimatorListing.vue'),
      },
      {
        path: '/manage/ive/estimator/land-listing-cloning/:id',
        name: 'land-estimator-listing-cloning',
        component: () =>
          import('@/views/manage/IVE/estimator/land/EstimatorListing.vue'),
      },
      {
        path: '/manage/ive/estimator/land-cloning/:id',
        name: 'land-estimator-cloning',
        component: () =>
          import('@/views/manage/IVE/estimator/land/EstimatorCloning.vue'),
      },
      {
        path: '/manage/ive/factor-options/facing-listing',
        name: 'facing-options-listing',
        component: () =>
          import('@/views/manage/IVE/factor-options/facing/FacingListing.vue'),
      },
      {
        path: '/manage/ive/factor-options/facing-creating',
        name: 'facing-options-creating',
        component: () =>
          import('@/views/manage/IVE/factor-options/facing/FacingCreating.vue'),
      },
      {
        path: '/manage/ive/factor-options/facing-editing/:id',
        name: 'facing-options-editing',
        component: () =>
          import('@/views/manage/IVE/factor-options/facing/FacingEditing.vue'),
      },
      {
        path: '/manage/ive/factor-options/sabah-city-listing',
        name: 'sabah-city-options-listing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/sabah-city-specs/SabahCityListing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/sabah-city-creating',
        name: 'sabah-city-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/sabah-city-specs/SabahCityCreating.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/sabah-city-editing/:id',
        name: 'sabah-city-options-editing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/sabah-city-specs/SabahCityEditing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/location-listing',
        name: 'location-options-listing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/location/LocationsListing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/location-creating',
        name: 'location-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/location/LocationsCreating.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/location-editing/:id',
        name: 'location-options-editing',
        component: () =>
          import(
            '@/views/manage/IVE/factor-options/location/LocationsEditing.vue'
          ),
      },
      {
        path: '/manage/ive/factor-options/curb-listing',
        name: 'curb-options-listing',
        component: () =>
          import('@/views/manage/IVE/factor-options/curb/CurbsListing.vue'),
      },
      {
        path: '/manage/ive/factor-options/curb-creating',
        name: 'curb-options-creating',
        component: () =>
          import('@/views/manage/IVE/factor-options/curb/CurbsCreating.vue'),
      },
      {
        path: '/manage/ive/factor-options/curb-editing/:id',
        name: 'curb-options-editing',
        component: () =>
          import('@/views/manage/IVE/factor-options/curb/CurbsEditing.vue'),
      },
      {
        path: '/manage/ive/building-factor-options/building-age-listing',
        name: 'building-age-options-listing',
        component: () =>
          import(
            '@/views/manage/IVE/building-factor-options/building-age/BuildingAgeListing.vue'
          ),
      },
      {
        path: '/manage/ive/building-factor-options/building-age-creating',
        name: 'building-age-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/building-factor-options/building-age/BuildingAgeCreating.vue'
          ),
      },
      {
        path: '/manage/ive/building-factor-options/building-age-editing/:id',
        name: 'building-age-options-editing',
        component: () =>
          import(
            '@/views/manage/IVE/building-factor-options/building-age/BuildingAgeEditing.vue'
          ),
      },
      {
        path: '/manage/ive/building-factor-options/floors-listing',
        name: 'floor-options-listing',
        component: () =>
          import(
            '@/views/manage/IVE/building-factor-options/floors/NoOfFloorsListing.vue'
          ),
      },
      {
        path: '/manage/ive/building-factor-options/floors-creating',
        name: 'floor-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/building-factor-options/floors/NoOfFloorsCreating.vue'
          ),
      },
      {
        path: '/manage/ive/building-factor-options/floors-editing/:id',
        name: 'floor-options-editing',
        component: () =>
          import(
            '@/views/manage/IVE/building-factor-options/floors/NoOfFloorsEditing.vue'
          ),
      },
      {
        path: '/manage/ive/area/areas-listing',
        name: 'ive-areas-listing',
        component: () => import('@/views/manage/IVE/areas/IveAreasListing.vue'),
      },
      {
        path: '/manage/ive/area/areas-creating',
        name: 'ive-areas-creating',
        component: () =>
          import('@/views/manage/IVE/areas/IveAreasCreating.vue'),
      },
      {
        path: '/manage/ive/area/areas-editing/:id',
        name: 'ive-areas-editing',
        component: () => import('@/views/manage/IVE/areas/IveAreasEditing.vue'),
      },
      {
        path: '/manage/ive/building-factor-options/fq-listing',
        name: 'finishing-quality-options-listing',
        component: () =>
          import(
            '@/views/manage/IVE/building-factor-options/finishing-quality/FinishingQualityListing.vue'
          ),
      },
      {
        path: '/manage/ive/building-factor-options/fq-creating',
        name: 'finishing-quality-options-creating',
        component: () =>
          import(
            '@/views/manage/IVE/building-factor-options/finishing-quality/FinishingQualityCreating.vue'
          ),
      },
      {
        path: '/manage/ive/building-factor-options/fq-editing/:id',
        name: 'finishing-quality-options-editing',
        component: () =>
          import(
            '@/views/manage/IVE/building-factor-options/finishing-quality/FinishingQualityEditing.vue'
          ),
      },
      {
        path: '/manage/loan-request/loan-funds-request-listing',
        name: 'loan-funds-request-listing',
        component: () =>
          import(
            '@/views/manage/loan-funds-request/LoanFundsRequestListing.vue'
          ),
      },
      {
        path: '/manage/loan-request/loan-funds-request-overview/:id',
        name: 'loans-funds-request-overview',
        component: () =>
          import(
            '@/views/manage/loan-funds-request/LoanFundsRequestOverView.vue'
          ),
      },
      {
        path: '/manage/loan-request/loan-funds-request-editing/:id',
        name: 'loans-funds-request-editing',
        component: () =>
          import(
            '@/views/manage/loan-funds-request/LoanFundsRequestEditing.vue'
          ),
      },
      {
        path: '/manage/subscriptions/ad-service-listing',
        name: 'ads-service-listing',
        component: () =>
          import(
            '@/views/manage/subscriptions/ads-service/AdsServiceListing.vue'
          ),
      },
      {
        path: '/manage/subscriptions/ad-service-creating',
        name: 'ads-service-creating',
        component: () =>
          import(
            '@/views/manage/subscriptions/ads-service/AdsServiceCreating.vue'
          ),
      },
      {
        path: '/manage/subscriptions/ad-service-editing/:id',
        name: 'ad-service-editing',
        component: () =>
          import(
            '@/views/manage/subscriptions/ads-service/AdsServiceEditing.vue'
          ),
      },
      {
        path: '/manage/subscriptions/ad-package-listing',
        name: 'ad-package-listing',
        component: () =>
          import(
            '@/views/manage/subscriptions/ads-packages/AdsPackagesListing.vue'
          ),
      },
      {
        path: '/manage/subscriptions/ad-package-creating',
        name: 'ad-package-creating',
        component: () =>
          import(
            '@/views/manage/subscriptions/ads-packages/AdsPackageCreating.vue'
          ),
      },
      {
        path: '/manage/subscriptions/ad-package-editing/:id',
        name: 'ad-package-editing',
        component: () =>
          import(
            '@/views/manage/subscriptions/ads-packages/AdsPackageEditing.vue'
          ),
      },
      {
        path: '/manage/ive/finishing-quality/ranges/quality-ranges-listing',
        name: 'quality-ranges-listing',
        component: () =>
          import(
            '@/views/manage/IVE/finishing-quality/ranges/QualityRangesListing.vue'
          ),
      },
      {
        path: '/manage/ive/finishing-quality/ranges/qulaity-ranges-creating',
        name: 'qulaity-ranges-creating',
        component: () =>
          import(
            '@/views/manage/IVE/finishing-quality/ranges/QualityRangesCreating.vue'
          ),
      },
      {
        path: '/manage/ive/finishing-quality/ranges/qulaity-ranges-editing/:id',
        name: 'qulaity-ranges-editing',
        component: () =>
          import(
            '@/views/manage/IVE/finishing-quality/ranges/QualityRangesEditing.vue'
          ),
      },
      {
        path: '/manage/ive/finishing-quality/groups/quality-groups-listing',
        name: 'quality-groups-listing',
        component: () =>
          import(
            '@/views/manage/IVE/finishing-quality/groups/QualityGroupsListing.vue'
          ),
      },
      {
        path: '/manage/ive/other/sea-front-listing',
        name: 'sea-front-listing',
        component: () =>
          import(
            '@/views/manage/IVE/other/sea-front-lengths/SeaFrontListing.vue'
          ),
      },
      {
        path: '/manage/ive/other/sea-front-editing/:id',
        name: 'sea-front-editing',
        component: () =>
          import(
            '@/views/manage/IVE/other/sea-front-lengths/SeaFrontEditing.vue'
          ),
      },
      {
        path: '/manage/ive/other/back-street-listing',
        name: 'back-street-listing',
        component: () =>
          import(
            '@/views/manage/IVE/other/back-street-lengths/BackStreetListing.vue'
          ),
      },
      {
        path: '/manage/ive/other/back-street-editing/:id',
        name: 'back-street-editing',
        component: () =>
          import(
            '@/views/manage/IVE/other/back-street-lengths/BackStreetEditing.vue'
          ),
      },
      {
        path: '/manage/assets/banners-listing',
        name: 'banners-listing',
        component: () =>
          import('@/views/manage/assets/banners/BannerListing.vue'),
      },
      {
        path: '/manage/assets/banners-creating',
        name: 'banner-creating',
        component: () =>
          import('@/views/manage/assets/banners/BannerCreating.vue'),
      },
      {
        path: '/manage/assets/banners-editing/:id',
        name: 'banner-editing',
        component: () =>
          import('@/views/manage/assets/banners/BannerEditing.vue'),
      },
      {
        path: '/manage/ire/areas-listing',
        name: 'ire-areas-listing',
        component: () => import('@/views/manage/IRE/areas/IreAreasListing.vue'),
      },
      {
        path: '/manage/ire/areas-creating',
        name: 'ire-areas-creating',
        component: () =>
          import('@/views/manage/IRE/areas/IreAreasCreating.vue'),
      },
      {
        path: '/manage/ire/areas-editing/:id',
        name: 'ire-areas-editing',
        component: () => import('@/views/manage/IRE/areas/IreAreasEditing.vue'),
      },
      {
        path: '/manage/ire/factors-bathrooms-listing',
        name: 'ire-factors-bathrooms-listing',
        component: () =>
          import('@/views/manage/IRE/factors/bathrooms/BathroomsListing.vue'),
      },

      {
        path: '/manage/ire/factors-bathrooms-editing/:id',
        name: 'ire-factors-bathrooms-editing',
        component: () =>
          import('@/views/manage/IRE/factors/bathrooms/BathroomsEditing.vue'),
      },
      {
        path: '/manage/ire/factors-bathrooms-creating',
        name: 'ire-factors-bathrooms-creating',
        component: () =>
          import('@/views/manage/IRE/factors/bathrooms/BathroomsCreating.vue'),
      },
      {
        path: '/manage/ire/factors-bathrooms-values',
        name: 'ire-factors-bathrooms-values',
        component: () =>
          import('@/views/manage/IRE/factors/bathrooms/BathroomsValues.vue'),
      },
      {
        path: '/manage/ire/apartment-type-listing',
        name: 'ire-apartment-type-listing',
        component: () =>
          import('@/views/manage/IRE/apartment-type/TypesListing.vue'),
      },
      {
        path: '/manage/ire/apartment-type-creating',
        name: 'ire-apartment-type-creating',
        component: () =>
          import('@/views/manage/IRE/apartment-type/TypesCreating.vue'),
      },
      {
        path: '/manage/ire/apartment-type-editing/:id',
        name: 'ire-apartment-type-editing',
        component: () =>
          import('@/views/manage/IRE/apartment-type/TypesEditing.vue'),
      },
      {
        path: '/manage/ire/sizes-listing',
        name: 'ire-sizes-listing',
        component: () => import('@/views/manage/IRE/size/SizesListing.vue'),
      },
      {
        path: '/manage/ire/sizes-creating',
        name: 'ire-sizes-creating',
        component: () => import('@/views/manage/IRE/size/SizesCreating.vue'),
      },
      {
        path: '/manage/ire/sizes-editing/:id',
        name: 'ire-sizes-editing',
        component: () => import('@/views/manage/IRE/size/SizesEditing.vue'),
      },
      {
        path: '/manage/ire/sizes-values',
        name: 'ire-sizes-values',
        component: () => import('@/views/manage/IRE/size/SizesValues.vue'),
      },
      {
        path: '/manage/ire/factors-options-listing',
        name: 'ire-factors-options-listing',
        component: () =>
          import('@/views/manage/IRE/factors/options/OptionsListing.vue'),
      },
      {
        path: '/manage/ire/factors-options-creating',
        name: 'ire-factors-options-creating',
        component: () =>
          import('@/views/manage/IRE/factors/options/OptionsCreating.vue'),
      },
      {
        path: '/manage/ire/factors-options-editing/:id',
        name: 'ire-factors-options-editing',
        component: () =>
          import('@/views/manage/IRE/factors/options/OptionsEditing.vue'),
      },
      {
        path: '/manage/ire/factors-options-values',
        name: 'ire-factors-options-values',
        component: () =>
          import('@/views/manage/IRE/factors/options/OptionsValues.vue'),
      },
      {
        path: '/manage/ire/factors-qualitys-listing',
        name: 'ire-factors-qualitys-listing',
        component: () =>
          import('@/views/manage/IRE/factors/quality/QualitysListing.vue'),
      },

      {
        path: '/manage/ire/factors-quality-editing/:id',
        name: 'ire-factors-qualitys-editing',
        component: () =>
          import('@/views/manage/IRE/factors/quality/QualitysEditing.vue'),
      },
      {
        path: '/manage/ire/factors-qualitys-values',
        name: 'ire-factors-qualitys-values',
        component: () =>
          import('@/views/manage/IRE/factors/quality/QualitysValues.vue'),
      },
      {
        path: '/manage/ire/factors-general-specs-listing',
        name: 'ire-factors-general-specs-listing',
        component: () =>
          import(
            '@/views/manage/IRE/factors/general-specs/GeneralSpecsListing.vue'
          ),
      },
      {
        path: '/manage/ire/factors-general-specs-creating',
        name: 'ire-factors-general-specs-creating',
        component: () =>
          import(
            '@/views/manage/IRE/factors/general-specs/GeneralSpecsCreating.vue'
          ),
      },
      {
        path: '/manage/ire/factors-general-specs-editing/:id',
        name: 'ire-factors-general-specs-editing',
        component: () =>
          import(
            '@/views/manage/IRE/factors/general-specs/GeneralSpecsEditing.vue'
          ),
      },
      {
        path: '/manage/ire/factors-general-specs-values',
        name: 'ire-factors-general-specs-values',
        component: () =>
          import(
            '@/views/manage/IRE/factors/general-specs/GeneralSpecsValues.vue'
          ),
      },
      {
        path: '/manage/ire/factors-location-specs-listing',
        name: 'ire-factors-location-specs-listing',
        component: () =>
          import(
            '@/views/manage/IRE/factors/location-specs/LocationsSpecsListing.vue'
          ),
      },
      {
        path: '/manage/ire/factors-location-specs-creating',
        name: 'ire-factors-location-specs-creating',
        component: () =>
          import(
            '@/views/manage/IRE/factors/location-specs/LocationSpecsCreating.vue'
          ),
      },
      {
        path: '/manage/ire/factors-location-specs-editing/:id',
        name: 'ire-factors-location-specs-editing',
        component: () =>
          import(
            '@/views/manage/IRE/factors/location-specs/LocationSpecsEditing.vue'
          ),
      },
      {
        path: '/manage/ire/factors-location-specs-values',
        name: 'ire-factors-location-specs-values',
        component: () =>
          import(
            '@/views/manage/IRE/factors/location-specs/LocationSpecsValues.vue'
          ),
      },
      {
        path: '/manage/ire/factors-property-services-listing',
        name: 'ire-factors-property-services-listing',
        component: () =>
          import(
            '@/views/manage/IRE/factors/property-services/PropertyServicesListing.vue'
          ),
      },
      {
        path: '/manage/ire/factors-property-services-creating',
        name: 'ire-factors-property-services-creating',
        component: () =>
          import(
            '@/views/manage/IRE/factors/property-services/PropertyServicesCreating.vue'
          ),
      },
      {
        path: '/manage/ire/factors-property-services-editing/:id',
        name: 'ire-factors-property-services-editing',
        component: () =>
          import(
            '@/views/manage/IRE/factors/property-services/PropertyServicesEditing.vue'
          ),
      },
      {
        path: '/manage/ire/factors-property-services-values',
        name: 'ire-factors-property-services-values',
        component: () =>
          import(
            '@/views/manage/IRE/factors/property-services/PropertyServicesValues.vue'
          ),
      },
      {
        path: '/manage/banks/bank-rates-listing',
        name: 'bank-rates-listing',
        component: () =>
          import('@/views/manage/banks/bank-rates/BankRatesListing.vue'),
      },
      {
        path: '/manage/banks/bank-rates-creating',
        name: 'bank-rates-creating',
        component: () =>
          import('@/views/manage/banks/bank-rates/BankRatesCreating.vue'),
      },
      {
        path: '/manage/banks/bank-rates-editing/:id',
        name: 'bank-rates-editing',
        component: () =>
          import('@/views/manage/banks/bank-rates/BankRatesEditing.vue'),
      },

      {
        path: '/manage/banks/admin-bank-rates-listing',
        name: 'admin-bank-rates-listing',
        component: () =>
          import('@/views/manage/banks/admin-bank-rates/AdminBanksListing.vue'),
      },
      {
        path: '/manage/banks/bank-rates-setting',
        name: 'bank-rates-setting',
        component: () =>
          import('@/views/manage/banks/bank-rates/BankRatesSetting.vue'),
      },
      {
        path: '/manage/banks/admin-bank-rates-overview/:id',
        name: 'admin-bank-rates-overview',
        component: () =>
          import(
            '@/views/manage/banks/admin-bank-rates/AdminBanksOverview.vue'
          ),
      },
      {
        path: '/manage/banks/bank-rates-log-reports',
        name: 'bank-rates-log-reports',
        component: () =>
          import('@/views/manage/banks/bank-rates/LogReportsListing.vue'),
      },
      {
        path: '/manage/assets/news-listing',
        name: 'news-listing',
        component: () =>
          import('@/views/manage/assets/manage-news/NewsListing.vue'),
      },
      {
        path: '/manage/assets/news-creating',
        name: 'news-creating',
        component: () =>
          import('@/views/manage/assets/manage-news/NewsCreating.vue'),
      },
      {
        path: '/manage/assets/news-editing/:id',
        name: 'news-editing',
        component: () =>
          import('@/views/manage/assets/manage-news/NewsEditing.vue'),
      },
      {
        path: '/manage/ire-v2/property-type-listing',
        name: 'property-type-listing',
        component: () =>
          import('@/views/manage/IREV2/property-type/PropertyTypeListing.vue'),
      },
      {
        path: '/manage/ire-v2/property-type-editing/:id',
        name: 'property-type-editing',
        component: () =>
          import('@/views/manage/IREV2/property-type/PropertyTypeEditing.vue'),
      },
      {
        path: '/manage/ire-v2/floor-type-listing',
        name: 'floor-type-listing',
        component: () =>
          import('@/views/manage/IREV2/floor-type/FloorListing.vue'),
      },
      {
        path: '/manage/ire-v2/floor-type-editing/:id',
        name: 'floor-type-editing',
        component: () =>
          import('@/views/manage/IREV2/floor-type/FloorEditing.vue'),
      },
      {
        path: '/manage/ire-v2/property-age-listing',
        name: 'property-age-listing',
        component: () =>
          import('@/views/manage/IREV2/property-age/PropertyAgeListing.vue'),
      },
      {
        path: '/manage/ire-v2/property-age-editing/:id',
        name: 'property-age-editing',
        component: () =>
          import('@/views/manage/IREV2/property-age/PropertyAgeEditing.vue'),
      },
      {
        path: '/manage/ire-v2/areas-listing',
        name: 'irev2-areas-listing',
        component: () =>
          import('@/views/manage/IREV2/areas/IreAreasListing.vue'),
      },
      {
        path: '/manage/ire-v2/areas-creating',
        name: 'irev2-areas-creating',
        component: () =>
          import('@/views/manage/IREV2/areas/IreAreasCreating.vue'),
      },
      {
        path: '/manage/ire-v2/areas-editing/:id',
        name: 'irev2-areas-editing',
        component: () =>
          import('@/views/manage/IREV2/areas/IreAreasEditing.vue'),
      },
      {
        path: '/manage/ire-v2/areas-values',
        name: 'irev2-areas-values',
        component: () =>
          import('@/views/manage/IREV2/areas/IreAreasValues.vue'),
      },
      {
        path: '/manage/ads/property-categories-listing',
        name: 'property-categories-listing',
        component: () =>
          import(
            '@/views/manage/ads/property-category/PropertyCategoryListing.vue'
          ),
      },
      {
        path: '/manage/ads/property-categories-creating',
        name: 'property-categories-creating',
        component: () =>
          import(
            '@/views/manage/ads/property-category/PropertyCategoryCreating.vue'
          ),
      },
      {
        path: '/manage/ads/property-categories-editing/:id',
        name: 'property-categories-editing',
        component: () =>
          import(
            '@/views/manage/ads/property-category/PropertyCategoryEditing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/areas-listing',
        name: 'ire-investment-areas-listing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/areas/IreInvestmentAreasListing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/areas-creating',
        name: 'ire-investment-areas-creating',
        component: () =>
          import(
            '@/views/manage/IREInvestment/areas/IreInvestmentAreasCreating.vue'
          ),
      },
      {
        path: '/manage/ire-investment/areas-editing/:id',
        name: 'ire-investment-areas-editing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/areas/IreInvestmentAreasEditing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/unit-values',
        name: 'ire-investment-unit-values',
        component: () =>
          import(
            '@/views/manage/IREInvestment/areas/IreInvestmentAreasValues.vue'
          ),
      },
      {
        path: '/manage/ire-investment/factors-location-specs-listing',
        name: 'ire-investment-factors-location-specs-listing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/factors/location-specs/LocationsSpecsListing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/factors-location-specs-creating',
        name: 'ire-investment-factors-location-specs-creating',
        component: () =>
          import(
            '@/views/manage/IREInvestment/factors/location-specs/LocationSpecsCreating.vue'
          ),
      },
      {
        path: '/manage/ire-investment/factors-location-specs-editing/:id',
        name: 'ire-investment-factors-location-specs-editing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/factors/location-specs/LocationSpecsEditing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/factors-property-services-listing',
        name: 'ire-investment-factors-property-services-listing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/factors/property-services/PropertyServicesListing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/factors-property-services-creating',
        name: 'ire-investment-factors-Property-service-creating',
        component: () =>
          import(
            '@/views/manage/IREInvestment/factors/property-services/PropertyServicesCreating.vue'
          ),
      },
      {
        path: '/manage/ire-investment/factors-property-services-editing/:id',
        name: 'ire-investment-factors-property-service-editing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/factors/property-services/PropertyServicesEditing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/factors-electricity-specs-listing',
        name: 'ire-investment-factors-electricity-specs-listing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/factors/electricity-specs/ElectricitySpecsListing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/factors-electricity-specs-creating',
        name: 'ire-investment-electricity-specs-creating',
        component: () =>
          import(
            '@/views/manage/IREInvestment/factors/electricity-specs/ElectricitySpecsCreating.vue'
          ),
      },
      {
        path: '/manage/ire-investment/factors-electricity-specs-editing/:id',
        name: 'ire-investment-factors-electricity-specs-editing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/factors/electricity-specs/ElectricitySpecsEditing.vue'
          ),
      },

      {
        path: '/manage/ire-investment/property-type-listing',
        name: 'ire-investment-property-type-listing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/property-type/PropertyTypeListing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/property-type-editing/:id',
        name: 'ire-investment-property-type-editing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/property-type/PropertyTypeEditing.vue'
          ),
      },

      {
        path: '/manage/ire-investment/property-age-listing',
        name: 'ire-investment-property-age-listing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/property-age/PropertyAgeListing.vue'
          ),
      },
      {
        path: '/manage/ire-investment/property-age-editing/:id',
        name: 'ire-investment-property-age-editing',
        component: () =>
          import(
            '@/views/manage/IREInvestment/property-age/PropertyAgeEditing.vue'
          ),
      },

      {
        path: '/manage/ire-investment/locations-listing',
        name: 'ire-investment-locations-listing',
        component: () =>
          import('@/views/manage/IREInvestment/locations/LocationsListing.vue'),
      },
      {
        path: '/manage/ire-investment/locations-editing/:id',
        name: 'ire-investment-locations-editing',
        component: () =>
          import('@/views/manage/IREInvestment/locations/LocationsEditing.vue'),
      },

      {
        path: '/manage/ire-investment/config-listing',
        name: 'ire-investment-config-listing',
        component: () =>
          import('@/views/manage/IREInvestment/config/ConfigListing.vue'),
      },
      {
        path: '/manage/ire-investment/config-editing/:id',
        name: 'ire-investment-config-editing',
        component: () =>
          import('@/views/manage/IREInvestment/config/ConfigEditing.vue'),
      },
      {
        path: '/manage/ire-literal/areas-listing',
        name: 'ire-literal-areas-listing',
        component: () =>
          import('@/views/manage/IRELiteral/areas/IreLiteralAreasListing.vue'),
      },
      {
        path: '/manage/ire-literal/areas-creating',
        name: 'ire-literal-areas-creating',
        component: () =>
          import('@/views/manage/IRELiteral/areas/IreLiteralAreasCreating.vue'),
      },
      {
        path: '/manage/ire-literal/areas-editing/:id',
        name: 'ire-literal-areas-editing',
        component: () =>
          import('@/views/manage/IRELiteral/areas/IreLiteralAreasEditing.vue'),
      },
      {
        path: '/manage/ire-literal/property-type-listing',
        name: 'ire-literal-property-type-listing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/property-type/PropertyTypeListing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/property-type-editing/:id',
        name: 'ire-literal-property-type-editing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/property-type/PropertyTypeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/property-age-listing',
        name: 'ire-literal-property-age-listing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/property-age/PropertyAgeListing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/property-age-editing/:id',
        name: 'ire-literal-property-age-editing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/property-age/PropertyAgeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/factors-property-services-listing',
        name: 'ire-literal-factors-property-services-listing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/factors/property-services/PropertyServicesListing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/factors-property-services-creating',
        name: 'ire-literal-factors-Property-service-creating',
        component: () =>
          import(
            '@/views/manage/IRELiteral/factors/property-services/PropertyServicesCreating.vue'
          ),
      },
      {
        path: '/manage/ire-literal/factors-property-services-editing/:id',
        name: 'ire-literal-factors-property-service-editing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/factors/property-services/PropertyServicesEditing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/direction-listing',
        name: 'ire-literal-direction-listing',
        component: () =>
          import('@/views/manage/IRELiteral/direction/DirectionListing.vue'),
      },
      {
        path: '/manage/ire-literal/direction-editing/:id',
        name: 'ire-literal-direction-editing',
        component: () =>
          import('@/views/manage/IRELiteral/direction/DirectionEditing.vue'),
      },
      {
        path: '/manage/ire-literal/floor-type-listing',
        name: 'ire-literal-floor-type-listing',
        component: () =>
          import('@/views/manage/IRELiteral/floor-type/FloorTypeListing.vue'),
      },
      {
        path: '/manage/ire-literal/floor-type-editing/:id',
        name: 'ire-literal-floor-type-editing',
        component: () =>
          import('@/views/manage/IRELiteral/floor-type/FloorTypeEditing.vue'),
      },
      {
        path: '/manage/ire-literal/quality-listing',
        name: 'ire-literal-quality-listing',
        component: () =>
          import('@/views/manage/IRELiteral/quality/QualityListing.vue'),
      },
      {
        path: '/manage/ire-literal/quality-editing/:id',
        name: 'ire-literal-quality-editing',
        component: () =>
          import('@/views/manage/IRELiteral/quality/QualityEditing.vue'),
      },
      {
        path: '/manage/ire-literal/street-listing',
        name: 'ire-literal-streets-listing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/street/IreCommonStreetsListing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/street-creating',
        name: 'ire-literal-streets-creating',
        component: () =>
          import(
            '@/views/manage/IRELiteral/street/IreCommonStreetsCreating.vue'
          ),
      },
      {
        path: '/manage/ire-literal/street-editing/:id',
        name: 'ire-literal-streets-editing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/street/IreCommonStreetsEditing.vue'
          ),
      },

      {
        path: '/manage/ire-literal/areas-listing',
        name: 'ire-literal-areas-listing',
        component: () =>
          import('@/views/manage/IRELiteral/areas/IreLiteralAreasListing.vue'),
      },
      {
        path: '/manage/ire-literal/areas-creating',
        name: 'ire-literal-areas-creating',
        component: () =>
          import('@/views/manage/IRELiteral/areas/IreLiteralAreasCreating.vue'),
      },
      {
        path: '/manage/ire-literal/areas-editing/:id',
        name: 'ire-literal-areas-editing',
        component: () =>
          import('@/views/manage/IRELiteral/areas/IreLiteralAreasEditing.vue'),
      },
      {
        path: '/manage/ire-literal/property-type-listing',
        name: 'ire-literal-property-type-listing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/property-type/PropertyTypeListing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/property-type-editing/:id',
        name: 'ire-literal-property-type-editing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/property-type/PropertyTypeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/property-age-listing',
        name: 'ire-literal-property-age-listing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/property-age/PropertyAgeListing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/property-age-editing/:id',
        name: 'ire-literal-property-age-editing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/property-age/PropertyAgeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/factors-property-services-listing',
        name: 'ire-literal-factors-property-services-listing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/factors/property-services/PropertyServicesListing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/factors-property-services-creating',
        name: 'ire-literal-factors-Property-service-creating',
        component: () =>
          import(
            '@/views/manage/IRELiteral/factors/property-services/PropertyServicesCreating.vue'
          ),
      },
      {
        path: '/manage/ire-literal/factors-property-services-editing/:id',
        name: 'ire-literal-factors-property-service-editing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/factors/property-services/PropertyServicesEditing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/direction-listing',
        name: 'ire-literal-direction-listing',
        component: () =>
          import('@/views/manage/IRELiteral/direction/DirectionListing.vue'),
      },
      {
        path: '/manage/ire-literal/direction-editing/:id',
        name: 'ire-literal-direction-editing',
        component: () =>
          import('@/views/manage/IRELiteral/direction/DirectionEditing.vue'),
      },
      {
        path: '/manage/ire-literal/floor-type-listing',
        name: 'ire-literal-floor-type-listing',
        component: () =>
          import('@/views/manage/IRELiteral/floor-type/FloorTypeListing.vue'),
      },
      {
        path: '/manage/ire-literal/floor-type-editing/:id',
        name: 'ire-literal-floor-type-editing',
        component: () =>
          import('@/views/manage/IRELiteral/floor-type/FloorTypeEditing.vue'),
      },
      {
        path: '/manage/ire-literal/quality-listing',
        name: 'ire-literal-quality-listing',
        component: () =>
          import('@/views/manage/IRELiteral/quality/QualityListing.vue'),
      },
      {
        path: '/manage/ire-literal/quality-editing/:id',
        name: 'ire-literal-quality-editing',
        component: () =>
          import('@/views/manage/IRELiteral/quality/QualityEditing.vue'),
      },
      {
        path: '/manage/ire-literal/street-listing',
        name: 'ire-literal-streets-listing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/street/IreCommonStreetsListing.vue'
          ),
      },
      {
        path: '/manage/ire-literal/street-creating',
        name: 'ire-literal-streets-creating',
        component: () =>
          import(
            '@/views/manage/IRELiteral/street/IreCommonStreetsCreating.vue'
          ),
      },
      {
        path: '/manage/ire-literal/street-editing/:id',
        name: 'ire-literal-streets-editing',
        component: () =>
          import(
            '@/views/manage/IRELiteral/street/IreCommonStreetsEditing.vue'
          ),
      },

      {
        path: '/manage/ire-commercial/areas-listing',
        name: 'ire-commercial-areas-listing',
        component: () =>
          import('@/views/manage/IRECommercial/areas/IreAreasListing.vue'),
      },
      {
        path: '/manage/ire-commercial/areas-creating',
        name: 'ire-commercial-areas-creating',
        component: () =>
          import('@/views/manage/IRECommercial/areas/IreAreasCreating.vue'),
      },
      {
        path: '/manage/ire-commercial/areas-editing/:id',
        name: 'ire-commercial-areas-editing',
        component: () =>
          import('@/views/manage/IRECommercial/areas/IreAreasEditing.vue'),
      },
      {
        path: '/manage/ire-commercial/property-type-listing',
        name: 'ire-commercial-property-type-listing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/property-type/PropertyTypeListing.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/property-type-editing/:id',
        name: 'ire-commercial-property-type-editing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/property-type/PropertyTypeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/property-age-listing',
        name: 'ire-commercial-property-age-listing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/property-age/PropertyAgeListing.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/property-age-editing/:id',
        name: 'ire-commercial-property-age-editing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/property-age/PropertyAgeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/factors-property-services-listing',
        name: 'ire-commercial-factors-property-services-listing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/factors/property-services/PropertyServicesListing.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/factors-property-services-creating',
        name: 'ire-commercial-factors-Property-service-creating',
        component: () =>
          import(
            '@/views/manage/IRECommercial/factors/property-services/PropertyServicesCreating.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/factors-property-services-editing/:id',
        name: 'ire-commercial-factors-property-service-editing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/factors/property-services/PropertyServicesEditing.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/direction-listing',
        name: 'ire-commercial-direction-listing',
        component: () =>
          import('@/views/manage/IRECommercial/direction/DirectionListing.vue'),
      },
      {
        path: '/manage/ire-commercial/direction-editing/:id',
        name: 'ire-commercial-direction-editing',
        component: () =>
          import('@/views/manage/IRECommercial/direction/DirectionEditing.vue'),
      },
      {
        path: '/manage/ire-commercial/floor-type-listing',
        name: 'ire-commercial-floor-type-listing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/floor-type/FloorTypeListing.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/floor-type-editing/:id',
        name: 'ire-commercial-floor-type-editing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/floor-type/FloorTypeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/quality-listing',
        name: 'ire-commercial-quality-listing',
        component: () =>
          import('@/views/manage/IRECommercial/quality/QualityListing.vue'),
      },
      {
        path: '/manage/ire-commercial/quality-editing/:id',
        name: 'ire-commercial-quality-editing',
        component: () =>
          import('@/views/manage/IRECommercial/quality/QualityEditing.vue'),
      },
      {
        path: '/manage/ire-commercial/street-listing',
        name: 'ire-commercial-streets-listing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/street/IreCommonStreetsListing.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/street-creating',
        name: 'ire-commercial-streets-creating',
        component: () =>
          import(
            '@/views/manage/IRECommercial/street/IreCommonStreetsCreating.vue'
          ),
      },
      {
        path: '/manage/ire-commercial/street-editing/:id',
        name: 'ire-commercial-streets-editing',
        component: () =>
          import(
            '@/views/manage/IRECommercial/street/IreCommonStreetsEditing.vue'
          ),
      },

      {
        path: '/manage/ire-industrial/areas-listing',
        name: 'ire-industrial-areas-listing',
        component: () =>
          import('@/views/manage/IREIndustrial/areas/IreAreasListing.vue'),
      },
      {
        path: '/manage/ire-industrial/areas-creating',
        name: 'ire-industrial-areas-creating',
        component: () =>
          import('@/views/manage/IREIndustrial/areas/IreAreasCreating.vue'),
      },
      {
        path: '/manage/ire-industrial/areas-editing/:id',
        name: 'ire-industrial-areas-editing',
        component: () =>
          import('@/views/manage/IREIndustrial/areas/IreAreasEditing.vue'),
      },
      {
        path: '/manage/ire-industrial/property-type-listing',
        name: 'ire-industrial-property-type-listing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/property-type/PropertyTypeListing.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/property-type-editing/:id',
        name: 'ire-industrial-property-type-editing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/property-type/PropertyTypeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/property-age-listing',
        name: 'ire-industrial-property-age-listing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/property-age/PropertyAgeListing.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/property-age-editing/:id',
        name: 'ire-industrial-property-age-editing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/property-age/PropertyAgeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/factors-property-services-listing',
        name: 'ire-industrial-factors-property-services-listing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/factors/property-services/PropertyServicesListing.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/factors-property-services-creating',
        name: 'ire-industrial-factors-Property-service-creating',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/factors/property-services/PropertyServicesCreating.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/factors-property-services-editing/:id',
        name: 'ire-industrial-factors-property-service-editing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/factors/property-services/PropertyServicesEditing.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/direction-listing',
        name: 'ire-industrial-direction-listing',
        component: () =>
          import('@/views/manage/IREIndustrial/direction/DirectionListing.vue'),
      },
      {
        path: '/manage/ire-industrial/direction-editing/:id',
        name: 'ire-industrial-direction-editing',
        component: () =>
          import('@/views/manage/IREIndustrial/direction/DirectionEditing.vue'),
      },
      {
        path: '/manage/ire-industrial/floor-type-listing',
        name: 'ire-industrial-floor-type-listing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/floor-type/FloorTypeListing.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/floor-type-editing/:id',
        name: 'ire-industrial-floor-type-editing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/floor-type/FloorTypeEditing.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/quality-listing',
        name: 'ire-industrial-quality-listing',
        component: () =>
          import('@/views/manage/IREIndustrial/quality/QualityListing.vue'),
      },
      {
        path: '/manage/ire-industrial/quality-editing/:id',
        name: 'ire-industrial-quality-editing',
        component: () =>
          import('@/views/manage/IREIndustrial/quality/QualityEditing.vue'),
      },
      {
        path: '/manage/ire-industrial/street-listing',
        name: 'ire-industrial-streets-listing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/street/IreCommonStreetsListing.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/street-creating',
        name: 'ire-industrial-streets-creating',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/street/IreCommonStreetsCreating.vue'
          ),
      },
      {
        path: '/manage/ire-industrial/street-editing/:id',
        name: 'ire-industrial-streets-editing',
        component: () =>
          import(
            '@/views/manage/IREIndustrial/street/IreCommonStreetsEditing.vue'
          ),
      },
      {
        path: '/manage/finance-request/finance-funds-request-listing',
        name: 'finance-funds-request-listing',
        component: () =>
          import(
            '@/views/manage/finance-funds-request/FinanceFundsRequestListing.vue'
          ),
      },
      {
        path: '/manage/finance-request/finance-funds-request-overview/:id',
        name: 'finances-funds-request-overview',
        component: () =>
          import(
            '@/views/manage/finance-funds-request/FinanceFundsRequestOverView.vue'
          ),
      },
      {
        path: '/manage/assets/home-services-listing',
        name: 'home-services-listing',
        component: () =>
          import('@/views/manage/assets/home-services/HomeServicesListing.vue'),
      },
      {
        path: '/manage/assets/home-service-editing/:id',
        name: 'home-services-editing',
        component: () =>
          import('@/views/manage/assets/home-services/HomeServicesEditing.vue'),
      },
    ],
  },

  {
    path: '/',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('@/views/authentication/SignIn.vue'),
        alias: ['sign-in-appraiser', 'sign-in-banks'],
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/errors/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
export default router;
