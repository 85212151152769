import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AreaObject {
  id: number;
  slug: string;
  titleEn: string;
  titleAr: string;
  isActive: boolean;
  title: string;
  isRegisteredTrades: boolean;
  isAd: boolean;
  isAuction: boolean;
  governorateId: number;
  governorateName: string;
}

export interface AreasListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  areas: Array<AreaObject>;
  errors: unknown;
}

@Module
export default class AreasModule extends VuexModule implements AreasListInfo {
  areas = [] as Array<AreaObject>;
  listDdlAreas = [] as Array<AreaObject>;
  listDdlGov = [] as Array<AreaObject>;
  listDdlAreasGov = [] as Array<AreaObject>;

  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get areas list
   * @returns Array<AreaObject>
   */
  get areasList(): Array<AreaObject> {
    return this.areas;
  }

  /**
   * Get all none ive areas list
   * @returns Array<AreaObject>
   */
  get allListAreas(): Array<AreaObject> {
    return this.listDdlAreas;
  }

  /**
   * Get all none ive areas list
   * @returns Array<AreaObject>
   */
  get allListGov(): Array<AreaObject> {
    return this.listDdlGov;
  }

  /**
   * Get all none ive areas list
   * @returns Array<AreaObject>
   */
  get allListAreasGov(): Array<AreaObject> {
    return this.listDdlAreasGov;
  }

  /**
   * Get total items
   * @returns number
   */
  get areasListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_NON_IVE_AREAS](data) {
    this.listDdlAreas = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_GOV](data) {
    this.listDdlGov = data.data;
  }

  @Mutation
  [Mutations.SET_GOV_AREAS](data) {
    this.listDdlAreasGov = data.data;
  }

  @Mutation
  [Mutations.SET_AREAS](data) {
    this.areas = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AREA_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_AREAS_LIST]() {
    this.areas = [] as Array<AreaObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_AREAS_STORE]() {
    this.context.commit(Mutations.RESET_AREAS_LIST);
  }

  @Action
  [Actions.CREATE_AREA](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/areas', data)
      .then(() => {
        this.context.commit(Mutations.RESET_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/areas', id)
      .then(() => {
        this.context.commit(Mutations.RESET_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_AREA]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/areas', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_AREA_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_AREA_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/areas', id);
  }

  @Action
  [Actions.GET_AREAS](govId = 0) {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/areas', {
      params: { page: this.currentPage, govId: govId },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_AREAS_LIST](options: { type?: string } = { type: 'all' }) {
    ApiService.setAuthorizationHeader();
    const params = {
      type: options.type,
    };
    return ApiService.query('/areas/findAllDdl', { params })
      .then(({ data }) => {
        // if (options.type != 'ire' && options.type != 'ive') {
        //   this.context.commit(Mutations.SET_AREAS, data);
        // } else {
        this.context.commit(Mutations.SET_NON_IVE_AREAS, data);
        // }
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_GOV_LIST](options: { type?: string } = { type: 'all' }) {
    ApiService.setAuthorizationHeader();
    const params = {
      type: options.type,
    };
    return ApiService.query('/areas/getAllGov', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_GOV, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.GET_AREAS_GOV_LIST](options: { type?: string } = { type: 'all' }) {
    ApiService.setAuthorizationHeader();
    const params = {
      type: options.type,
    };
    return ApiService.query('/areas/getAllNullGov', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_GOV_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  }

  @Action
  [Actions.UPDATE_AREAS_GOV]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/areas/link-to-gov', id, data)
      .then(() => {
        // this.context.commit(Mutations.RESET_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.GET_REJECT_ADS_REASON]() {
    ApiService.setAuthorizationHeader();

    return ApiService.get('/settings/reject-reason-list');
  }
}
